import { ChangeDetectionStrategy, Component } from '@angular/core'
import { map, startWith } from 'rxjs'
import { DEFAULT_JOB_NOTIFICATION_VALUE, IJobNotificationSettings, INotificationConfig, RequiredCandidateDetailKeys } from 'shared-lib'
import { EmployerStore } from '../../employer.store'

@Component({
  selector: 'job-application-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngrxLet="saving$ as saving">
      <e11-panel *ngrxLet="loading$ as loading">
        <div class="e11-flex e11-justify-end e11-items-center e11-mb-4">
          <e11-button
            [value]="'Save changes' | translate"
            color="primary-accent"
            size="sm"
            (click)="onSubmit()"
            [disabled]="loading || saving"
            [loading]="saving"
          ></e11-button>
        </div>
        <!-- Required Contact Details -->
        <ng-container *ngrxLet="employer$ as employer">
          <div></div>
          <required-contact-details
            (valueChanges)="requiredFields = $event"
            [loading]="loading || saving"
            [requiredFields]="employer?.requiredFields"
          ></required-contact-details>
          <e11-divider />
        </ng-container>

        <div class="e11-flex e11-justify-between e11-items-center e11-mb-4">
          <h3
            class="e11-font-primary-bold"
            [ngClass]="{
              'e11-skeleton e11-text-transparent': loading
            }"
          >
            {{ 'Automated Job Notifications' | translate }}
          </h3>
        </div>
        <div class="e11-flex e11-flex-col e11-gap-6" *ngrxLet="initialData$ as initialValue">
          <!-- JOB POST CLOSURE -->
          <job-notification-config-form
            [titleText]="'Job Post Closure Notification' | translate"
            [subtitleText]="
              'Would you like to notify all candidates when a job post has been closed? Select the types of notifications to send.' | translate
            "
            [initialValue]="initialValue?.jobPostClosure || defaultValue"
            (onValueChange)="handleValueChange($event, 'jobPostClosure')"
            [loading]="loading || saving"
            [disabled]="loading || saving"
          ></job-notification-config-form>
          <!-- CANDIDATE REJECTION -->
          <job-notification-config-form
            [titleText]="'Candidate Rejection Notification' | translate"
            [subtitleText]="
              'Would you like to notify each candidate when they are no longer being considered for a position? Select the types of notifications to send.'
                | translate
            "
            [initialValue]="initialValue?.candidateRejection || defaultValue"
            (onValueChange)="handleValueChange($event, 'candidateRejection')"
            [loading]="loading || saving"
            [disabled]="loading || saving"
          ></job-notification-config-form>
        </div>
      </e11-panel>
    </ng-container>
  `,
})
export class JobApplicationSettingsComponent {
  formData: IJobNotificationSettings = {
    candidateRejection: DEFAULT_JOB_NOTIFICATION_VALUE,
    jobPostClosure: DEFAULT_JOB_NOTIFICATION_VALUE,
  }
  requiredFields?: RequiredCandidateDetailKeys[]

  defaultValue = DEFAULT_JOB_NOTIFICATION_VALUE

  handleValueChange(value: INotificationConfig, field: keyof IJobNotificationSettings) {
    this.formData = {
      ...this.formData,
      [field]: value,
    }
  }

  constructor(private employerStore: EmployerStore) {}

  onSubmit() {
    const updatedData = {
      jobNotificationSettings: this.formData,
      requiredFields: this.requiredFields,
    }
    this.employerStore.onUpdateEmployer(updatedData)
  }
  employer$ = this.employerStore.employer$
  initialData$ = this.employerStore.employer$.pipe(
    map(employer => employer?.jobNotificationSettings),
    startWith(undefined)
  )
  loading$ = this.employerStore.employerLoaded$.pipe(
    map(loaded => !loaded),
    startWith(true)
  )
  saving$ = this.employerStore.employerUpdating$.pipe(startWith(false))
}
