import { animate, style, transition, trigger } from '@angular/animations'
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'
import { Permission } from '@employer/app/config/permission.config'
import { EmployerNavigationService, ROUTES } from '@employer/app/services/navigation.service'
import { PermissionStore } from '@engineering11/access-web'

import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { INavBottomTab } from '@engineering11/ui-lib/e11-nav-left'
import { E11SideOverContainerComponent } from '@engineering11/ui-lib/e11-side-over'
import { ViewportService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import { userActions } from 'shared-lib'
import { ExtendedNavId } from './main-layout.interface'

@Component({
  selector: 'app-main-layout',
  template: `
    <sdk-company-session-timeout (onTimeout)="logout()"></sdk-company-session-timeout>
    <ng-container *ngrxLet="extendedNavContent$ as extendedNavContent">
      <ng-container *ngrxLet="isMobile$ as isMobile">
        <ng-container *ngrxLet="userPermissions$ as permissions">
          <div class="e11-h-screen e11-overflow-hidden e11-flex e11-transition-all e11-duration-300">
            <div class="e11-flex">
              <nav
                class="e11-hidden lg:e11-flex lg:e11-gap-4 e11-h-full e11-flex-col e11-bg-skin-app-bg-dark "
                [ngClass]="{ 'e11-w-72 e11-p-6': !extendedNavContent, 'e11-w-20 e11-p-4': extendedNavContent }"
              >
                <a
                  class="e11-hidden e11-header-logo lg:e11-flex e11-items-center e11-justify-center e11-cursor-pointer"
                  [routerLink]="permissions?.has(Permission.ViewJobs) ? ROUTES.JOBS : ROUTES.CLIENT_MANAGEMENT"
                >
                  <img
                    [src]="extendedNavContent ? collapsedLogoUrl : logoUrl"
                    class="e11-object-contain"
                    [ngClass]="{ 'e11-max-h-32 e11-w-10': extendedNavContent, 'e11-max-h-24': !extendedNavContent }"
                  />
                </a>
                <ng-container *ngTemplateOutlet="navItem"></ng-container>
              </nav>
              <ng-container *ngrxLet="extendedNavContent$ as extendedNavContent">
                <div
                  [@slideInOut]
                  *ngIf="extendedNavContent && !isMobile"
                  class="e11-hidden lg:e11-flex e11-w-72 e11-h-full e11-overflow-y-auto e11-p-4"
                  [ngClass]="{
                    'e11-bg-skin-app-bg-dark e11-border-l e11-border-l-skin-grey/30': extendedNavContent === ExtendedNavId.Messaging,
                    'e11-bg-skin-white': extendedNavContent === ExtendedNavId.Reports,
                  }"
                >
                  <messaging-extended-nav *ngIf="extendedNavContent === ExtendedNavId.Messaging" />
                  <reports-extended-nav *ngIf="extendedNavContent === ExtendedNavId.Reports" />
                </div>
              </ng-container>
            </div>

            <main class="e11-flex-1 e11-flex-col e11-min-w-0 e11-h-screen">
              <app-header [mobileDrawerState]="mobileDrawerState" (mobileDrawerStateChange)="mobileDrawerStateChange($event)"></app-header>
              <div
                #mainLayoutScrollableElement
                class="e11-flex e11-flex-col e11-overflow-y-scroll e11-overflow-x-hidden e11-h-screen e11-px-6 e11-pt-6 e11-pb-16"
              >
                <router-outlet></router-outlet>
                <app-footer class="e11-mt-auto"></app-footer>
              </div>
            </main>
          </div>
        </ng-container>
        <e11-side-over-container
          #mobileNavSideOver
          [size]="'lg'"
          [sideOverPosition]="'left'"
          [containerClassOverrides]="'e11-bg-transparent e11-w-3/4'"
        >
          <ng-template e11Template="content">
            <div class="e11-bg-skin-app-bg-dark e11-h-full e11-flex e11-flex-col e11-shadow-xl">
              <img [src]="logoUrl" class="e11-h-16 e11-max-h-16 e11-object-contain e11-object-left e11-px-6" />
              <ng-container *ngTemplateOutlet="navItem"></ng-container>
            </div>
          </ng-template>
        </e11-side-over-container>

        <ng-template #navItem>
          <ng-container *ngrxLet="tabsConfig$ as tabsConfig">
            <e11-nav-left
              class="e11-flex-grow e11-overflow-y-auto"
              [navigationList]="tabsConfig"
              [mobileTabs]="mobileTabs"
              [mobileSideDrawerToggled]="mobileDrawerState"
              (mobileSideDrawerToggledChange)="mobileDrawerState = $event; mobileNavSideOver.close()"
              [iconOnly]="!!extendedNavContent"
            >
              <ng-template e11Template="message">
                <custom-messaging-nav [isMessagingMenuOpen]="mobileDrawerState" [iconOnly]="!isMobile && !!extendedNavContent"></custom-messaging-nav>
              </ng-template>

              <!-- <ng-template e11Template="customer-client">
            <customer-nav></customer-nav>
          </ng-template> -->
            </e11-nav-left>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  `,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-80px)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)', width: '*' })),
      ]),
    ]),
  ],
})
export class MainLayoutComponent implements AfterViewInit {
  @ViewChild('mobileNavSideOver') mobileNavSideOver!: E11SideOverContainerComponent
  @ViewChild('mainLayoutScrollableElement') mainLayoutScrollableElement!: ElementRef
  mobileTabs: INavBottomTab[] = [
    {
      id: 'home',
      name: 'Home',
      routeGroup: 'main',
      icon: 'home',
    },
    {
      id: 'message',
      name: 'Message',
      routeGroup: 'messaging',
      icon: 'message',
    },
  ]

  logoUrl = this.appConfigService.config.brand.logoImageLightUrl
  collapsedLogoUrl = this.appConfigService.config.brand.favIconUrl
  mobileDrawerState = false
  tabsConfig$ = this.employerNavigationService.currentMenuStatus$

  userPermissions$ = this.permissionStore.userPermissions$

  Permission = Permission
  ROUTES = ROUTES

  extendedNavContent$ = this.employerNavigationService.extendedNavContent$
  isMobile$ = this.viewportService.belowBreakpoint('lg')

  ExtendedNavId = ExtendedNavId

  constructor(
    private appConfigService: AppConfigService,
    private employerNavigationService: EmployerNavigationService,
    private permissionStore: PermissionStore,
    private router: Router,
    private store: Store,
    private viewportService: ViewportService,
    private cdr: ChangeDetectorRef
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationStart)).subscribe({
      next: _ => this.mobileDrawerStateChange(false),
    })
  }

  ngAfterViewInit() {
    this.setMainLayoutScrollableElement()
    this.cdr.detectChanges()
  }

  setMainLayoutScrollableElement() {
    this.employerNavigationService.setMainLayoutScrollableElement(this.mainLayoutScrollableElement)
  }

  mobileDrawerStateChange(isOpen: boolean) {
    if (!this.mobileNavSideOver) return
    this.mobileDrawerState = isOpen
    if (isOpen) {
      this.mobileNavSideOver.open()
      return
    }

    this.mobileNavSideOver.close()
  }

  logout() {
    this.store.dispatch(userActions.OnLogOut())
  }
}
