import { Injectable } from '@angular/core'
import { EmployerNavigationService } from '@employer/app/services/navigation.service'
import { IConversation, MessagingNavigationService } from '@engineering11/messaging-web'
import { E11ErrorHandlerService, toE11Error } from '@engineering11/web-api-error'
import { CandidatePoolDmConfigProvider, ConversationTypes, ICandidatePoolConversationMetadata } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class EmployerCandidatePoolConversationDMProvider extends CandidatePoolDmConfigProvider {
  conversationType = ConversationTypes.CandidatePoolDM

  constructor(
    private navigationService: EmployerNavigationService,
    messagingNavigationService: MessagingNavigationService,
    private errorHandler: E11ErrorHandlerService
  ) {
    super(messagingNavigationService)
    this.setConfig({ canViewAllReadReceipts: true })
  }

  onConversationClick(conversation: IConversation<ICandidatePoolConversationMetadata>): void {
    const { candidatePoolId } = conversation.clientMetadata!

    try {
      this.navigationService.toCandidatePoolMessage(candidatePoolId)
    } catch (err: any) {
      this.errorHandler.handleError(
        toE11Error(err, {
          type: 'messaging/route-failure',
          title: 'Failed to navigate',
          additionalData: conversation,
        })
      )
      super.onConversationClick(conversation) // fallback if navigation fails
    }
  }
}
