/**
 * The types of things it is valid to perform autocomplete on
 */
export enum AutocompleteType {
  Skill = 'skill',
  Certification = 'certification',
}

/**
 * @member name: The field we want to get back
 * @member search: The search key - should be lowercase of the name
 */
export interface IAutocomplete {
  id: string
  customerKey: string
  type: AutocompleteType
  name: string
  search: string
  useCount?: number
}
