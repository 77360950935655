import { Injectable } from '@angular/core'
import { selectors } from '@employer/app/store/selectors'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { NotificationHelper } from '@engineering11/web-ui-helpers'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { Observable, filter, finalize, from, switchMap, tap, withLatestFrom } from 'rxjs'
import { EmployerService, IEmployer } from 'shared-lib'

export interface IEmployerStore {
  employer?: IEmployer
  employerLoaded: boolean
  employerUpdating: boolean
}

const initialState: IEmployerStore = {
  employerLoaded: false,
  employerUpdating: false,
}

@Injectable({ providedIn: 'root' })
export class EmployerStore extends ComponentStore<IEmployerStore> {
  private readonly customerKey$ = this.store.select(selectors.getCustomerKey$)
  constructor(
    private employerService: EmployerService,
    private store: Store,
    private errorHandler: E11ErrorHandlerService,
    private notificationHelper: NotificationHelper,
    private logger: E11Logger
  ) {
    super(initialState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)
  readonly employer$ = this.select(s => s.employer)
  readonly employerLoaded$ = this.select(s => s.employerLoaded)
  readonly employerUpdating$ = this.select(s => s.employerUpdating)

  // EFFECTS
  readonly onGetEmployer = this.effect((_$: Observable<void>) =>
    _$.pipe(
      switchMap(_ => this.customerKey$.pipe(filter(isNotNil))),
      switchMap(customerKey => {
        return this.employerService.get(customerKey).pipe(
          tapResponse(
            response => {
              const employer: IEmployer = response ?? { id: customerKey, showAbout: false, showLegalDisclaimer: false, showCultureStatement: false }
              return this.setEmployer(employer)
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly onUpdateEmployer = this.effect((update$: Observable<Partial<IEmployer>>) =>
    update$.pipe(
      tap(() => this.setEmployerUpdating(true)),
      withLatestFrom(this.employer$),
      switchMap(([update, currentEmployer]) => {
        const employer: IEmployer = { ...currentEmployer!, ...update }
        return from(this.employerService.set(employer)).pipe(
          tapResponse(
            res => {
              this.notificationHelper.popNotification({
                title: 'Success',
                message: 'This content has been saved',
                type: 'success',
                autoClose: true,
                dismissOnRouteChange: true,
              })
              this.setEmployer(employer)
            },
            (error: Error) => this.errorHandler.handleError(error)
          ),
          finalize(() => this.setEmployerUpdating(false))
        )
      })
    )
  )

  // UPDATERS
  private readonly setEmployer = this.updater((s, employer: IEmployer) => ({ ...s, employer, employerLoaded: true }))
  private readonly setEmployerUpdating = this.updater((s, employerUpdating: boolean) => ({ ...s, employerUpdating }))
}
