import { Injectable } from '@angular/core'
import { selectors } from '@employer/app/store/selectors'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { NotificationHelper } from '@engineering11/web-ui-helpers'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { Store } from '@ngrx/store'
import { finalize, from, mergeMap, Observable, switchMap, withLatestFrom } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { ICompanyAutomationFormData } from './job-post-automation-company.repository'
import { JobsPostAutomationCompanySettingsService } from './jobs-post-automation-company-settings.service'

export interface IJobsPostAutomationCompanySettingsStore {
  loaded: boolean
  saving: boolean
  companyAutomation?: ICompanyAutomationFormData
}

const initialState: IJobsPostAutomationCompanySettingsStore = {
  loaded: false,
  saving: false,
  companyAutomation: undefined,
}

@Injectable({ providedIn: 'root' })
export class JobsPostAutomationCompanySettingsStore extends ComponentStore<IJobsPostAutomationCompanySettingsStore> {
  constructor(
    private jobsPostAutomationCompanyService: JobsPostAutomationCompanySettingsService,
    private errorHandler: E11ErrorHandlerService,
    private notificationHelper: NotificationHelper,
    private store: Store
  ) {
    super(initialState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)
  readonly formData$: Observable<ICompanyAutomationFormData> = this.select(s => s.companyAutomation!)
  readonly saving$ = this.select(s => s.saving)
  readonly loaded$ = this.select(s => s.loaded)

  // EFFECTS
  readonly onGetAutomation = this.effect((_$: Observable<void>) =>
    _$.pipe(
      switchMap(() => this.store.select(selectors.getCustomerKey$).pipe(filter(isNotNil))),
      mergeMap(customerKey => {
        this.setLoaded(false)
        this.setCurrentAutomationId(customerKey)
        return this.jobsPostAutomationCompanyService.getCurrentCustomerAutomationConfig()
      }),
      map(automation => this.setAutomation(automation))
    )
  )

  readonly onSaveAutomation = this.effect((formData$: Observable<ICompanyAutomationFormData>) =>
    formData$.pipe(
      withLatestFrom(this.store.select(selectors.getCustomerKey$).pipe(filter(isNotNil))),
      mergeMap(([formData, customerKey]) => {
        this.setSaving(true)
        return from(this.jobsPostAutomationCompanyService.setAutomation({ id: customerKey, ...formData })).pipe(
          tapResponse(
            res => {
              this.setAutomation(res)
              this.notificationHelper.popNotification(savedSuccessNotification)
            },
            (err: Error) => {
              this.errorHandler.handleError(err, { alertUser: true })
            }
          ),
          finalize(() => this.setSaving(false))
        )
      })
    )
  )

  // UPDATERS

  private readonly setCurrentAutomationId = this.updater((s, currentAutomationId: string) => ({
    ...s,
    currentAutomationId,
  }))

  private readonly setAutomation = this.updater((s, currentAutomation: ICompanyAutomationFormData) => ({
    ...s,
    companyAutomation: currentAutomation,
    loaded: true,
  }))

  private readonly setLoaded = this.updater((s, loaded: boolean) => ({ ...s, loaded }))
  private readonly setSaving = this.updater((s, saving: boolean) => ({ ...s, saving }))
}

const savedSuccessNotification: INotificationMessage = {
  type: 'success',
  title: 'Saved',
  message: 'Successfully saved automation',
}
