import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { EmployerNavigationService } from '@employer/app/services/navigation.service'
import { ConfigStore } from '@engineering11/config-web'
import { ConversationStore, openConversationFilter } from '@engineering11/messaging-web'
import { ViewportService } from '@engineering11/web-utilities'
import { ConversationTypes } from 'shared-lib'
import { ExtendedNavId } from '../../layout/main-layout.interface'

@Component({
  selector: 'custom-messaging-nav',
  styleUrls: ['./messaging-nav.component.scss'],
  template: `
    <ng-container *ngrxLet="isMobile$ as isMobile">
      <ul>
        <li
          (click)="toggleMessagingMenu(isMobile)"
          [ngClass]="{
            'e11-border-b-transparent e11-bg-skin-white/20 e11-text-white': isMessagingMenuOpen && !iconOnly,
            'e11-border-b-skin-grey/30': !isMessagingMenuOpen
          }"
          class="e11-flex e11-rounded-md e11-h-12 e11-px-2 e11-justify-between e11-items-center hover:e11-text-white e11-cursor-pointer e11-mb-2"
          [ngClass]="{ 'e11-border-b': !iconOnly }"
        >
          <div class="e11-flex e11-items-center e11-w-full" [ngClass]="{ 'e11-justify-center': iconOnly }">
            <span class="material-icons " [ngClass]="{ 'md-24': iconOnly, 'e11-mr-2 md-18': !iconOnly }"> message </span>
            <span class="e11-font-medium e11-text-md e11-relative" *ngIf="!iconOnly && !isMobile">
              {{ 'Messages' | translate }}
              <e11-badge
                *ngIf="unreadConversationCount$ | async"
                [color]="'error'"
                [limit]="UNREAD_LIMIT"
                [posAdjustX]="'-4px'"
                [posAdjustY]="'-11px'"
                [size]="'xs'"
                [value]="(unreadConversationCount$ | async) ?? 0"
              ></e11-badge>
            </span>
          </div>

          <span
            *ngIf="!iconOnly"
            [ngClass]="{ 'nav-icon-active': isMessagingMenuOpen }"
            class="material-icons-outlined e11-text-skin-extra-light nav-icon"
          >
            expand_more
          </span>
        </li>

        <div
          *ngIf="!iconOnly && isMobile"
          [ngClass]="{ 'nav-messages-container-active e11-border-b e11-border-b-skin-grey/30': isMessagingMenuOpen }"
          class="nav-messages-container"
        >
          <messaging-nav-groups></messaging-nav-groups>
        </div>
      </ul>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingNavComponent implements OnInit {
  features$ = this.configStore.features$
  @Input() isMessagingMenuOpen = false
  @Input() iconOnly = false

  isMobile$ = this.viewportService.belowBreakpoint('lg')
  unreadConversationCount$ = this.conversationStore.getUnreadConversationCountForQuery({
    types: [ConversationTypes.JobApplication, ConversationTypes.JobApplicationDM, ConversationTypes.CandidatePoolDM],
    customFilter: openConversationFilter,
  })

  UNREAD_LIMIT = 9

  constructor(
    private conversationStore: ConversationStore,
    private configStore: ConfigStore,
    private viewportService: ViewportService,
    private navigationService: EmployerNavigationService
  ) {}

  ngOnInit() {}

  toggleMessagingMenu(isMobile: boolean) {
    this.isMessagingMenuOpen = !this.isMessagingMenuOpen

    if (!isMobile) {
      this.navigationService.setExtendedNavContent(ExtendedNavId.Messaging)
    }
  }
}
