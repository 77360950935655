import { NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { E11DynamicFormBuilderComponent, E11DynamicFormModule } from '@engineering11/ui-lib/e11-form-builder'
import { TranslateModule } from '@ngx-translate/core'
import { INotificationConfig } from 'shared-lib'
import { buildNotificationConfigForm } from './job-application-settings.util'

@Component({
  selector: 'job-notification-config-form',
  standalone: true,
  imports: [TranslateModule, E11DynamicFormModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div>
    <h5 class="e11-font-primary-demibold" *ngIf="titleText">
      {{ titleText }}
    </h5>
    <p class="e11-text-sm" *ngIf="subtitleText">
      {{ subtitleText }}
    </p>
    <e11-dynamic-form-builder
      #notificationForm
      [fieldGroups]="fieldGroups"
      [initialValue]="initialValue"
      [hideSubmitMenu]="true"
      [loading]="loading"
      [disabled]="disabled"
      (valueChanges)="onValueChange.emit($event)"
    >
      <ng-template e11Template="mobileTemplate">
        <span class="e11-text-xs">({{ 'mobile' | translate }})</span>
      </ng-template>
    </e11-dynamic-form-builder>
  </div>`,
})
export class JobNotificationConfigFormComponent {
  @ViewChild('notificationForm') notificationForm!: E11DynamicFormBuilderComponent<INotificationConfig>
  fieldGroups = buildNotificationConfigForm()
  @Input() titleText?: string
  @Input() subtitleText?: string
  @Input() initialValue?: INotificationConfig
  @Input() loading = false
  @Input() disabled = false

  @Output() onValueChange = new EventEmitter<INotificationConfig>()
}
