import { Injectable } from '@angular/core'
import { JobPostApplicationRepository } from '@employer/app/repositories/job-post-application.repository'
import { IFileReceipt } from '@engineering11/files-web'
import { FieldValue } from '@engineering11/web-api-firebase'
import { APPLICATION_STATE, IRequestedUpdate } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class JobPostApplicationService {
  constructor(public repository: JobPostApplicationRepository) {}

  getApplications(jobPostId: string) {
    return this.repository.getAllValueChanges(jobPostId)
  }

  getApplicationsByStatusInfiniteScroll(jobPostId: string, status: APPLICATION_STATE, limit = 100) {
    return this.repository.getAllValueChangesByStatus(jobPostId, status, limit)
  }

  getApplicationsCountByStatus(jobPostId: string, status: APPLICATION_STATE) {
    return this.repository.getCountByStatus(jobPostId, status)
  }

  addRequestedUpdate(jobPostId: string, applicationId: string, requestedUpdate: IRequestedUpdate[]) {
    return this.repository.update({ id: applicationId, jobPostId, requestedUpdated: FieldValue.arrayUnion(...requestedUpdate) })
  }

  addConversationId(jobPostId: string, applicationId: string, conversationId: string) {
    return this.repository.update({ id: applicationId, jobPostId, conversationId })
  }

  addApplicationFiles(jobPostId: string, applicationId: string, applicationFiles: IFileReceipt[]) {
    return this.repository.update({ id: applicationId, jobPostId, applicationFiles })
  }

  removeApplicationFiles(jobPostId: string, applicationId: string, applicationFiles: IFileReceipt[]) {
    return this.repository.update({ id: applicationId, jobPostId, applicationFiles: FieldValue.arrayRemove(...applicationFiles) })
  }
}
