import { Injectable } from '@angular/core'
import { FilterTransformationFn } from '@engineering11/reporting-web'
import { BehaviorSubject, distinctUntilChanged, map, Observable, Subject } from 'rxjs'
import { ICareersPageReportingFilter } from '../modules/reports/reports.interface'
import { transformGlobalDateFilters } from '../modules/reports/reports.util'

@Injectable({ providedIn: 'root' })
export class ReportNavService {
  private reportDateTypeSubject = new BehaviorSubject<string>('jobPostCreatedAt')
  reportDateType$ = this.reportDateTypeSubject.asObservable().pipe(distinctUntilChanged())

  private careersPageDateFilterSubject = new Subject<ICareersPageReportingFilter>()
  careersPageDateFilter$ = this.careersPageDateFilterSubject.asObservable()

  updateReportDateType(dateColumn: string) {
    this.reportDateTypeSubject.next(dateColumn)
  }

  setCareersPageDateFilter(dateFilter: ICareersPageReportingFilter) {
    this.careersPageDateFilterSubject.next(dateFilter)
  }

  getGlobalDateFilterTransformationFn(tableAlias: string = 'j'): Observable<FilterTransformationFn> {
    return this.reportDateType$.pipe(
      map(dateColumn => {
        switch (dateColumn) {
          case '__updatedAt':
            return `STRING(timestamp(timestamp_seconds(INT64(${tableAlias}.__updatedAt._seconds))))`
          case 'publishedDate':
            return `STRING(timestamp(timestamp_seconds(INT64(${tableAlias}.publishedDate._seconds))))`
          case 'jobPostCreatedAt':
            return `STRING(timestamp(timestamp_seconds(INT64(${tableAlias}.jobPostCreatedAt._seconds))))`
          default:
            return dateColumn
        }
      }),
      map(dateColumn => transformGlobalDateFilters(dateColumn))
    )
  }
}
