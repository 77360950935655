import { Injectable } from '@angular/core'
import { IPublishedJobProfile } from '@employer/app/models/job-profile.model'
import { PublishedJobProfileRepository } from '@employer/app/repositories/job-profile-published.repository'
import { FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { BehaviorSubject, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class JobProfileService {
  constructor(private repository: PublishedJobProfileRepository) {}
  get(id: string): Observable<IPublishedJobProfile | undefined> {
    return this.repository.get(id)
  }
  getValueChanges(id: string): Observable<IPublishedJobProfile | undefined> {
    return this.repository.getValueChanges(id)
  }
  getAll(customerKey: string): Observable<Array<IPublishedJobProfile>> {
    return this.repository.getAllByCustomer(customerKey)
  }
  getAllValueChanges(customerKey: string): Observable<Array<IPublishedJobProfile>> {
    return this.repository.getAllValueChangesByCustomer(customerKey)
  }

  getAllOrderByTitle(customerKey: string) {
    const queryFn = new FirestoreQueryBuilder().where('customerKey', '==', customerKey).orderBy('title')
    return this.repository.queryValueChanges(queryFn)
  }

  queryAutoComplete(customerKey: string, start: BehaviorSubject<string>): Observable<IPublishedJobProfile[]> {
    return start.pipe(
      switchMap((startText: string) => {
        // startText = startText.toLocaleLowerCase()
        const endText = startText + '\uf8ff'
        //TODO: Fix FirestoreQueryBuilder startAt and endAt and switch to that
        const queryFn = new FirestoreQueryBuilder()
          .where('customerKey', '==', customerKey)
          .orderBy('title')
          .startAt(startText)
          .endAt(endText)
          .limit(10)
        return this.repository.queryValueChanges(queryFn)
      })
    )
  }
}
