import { ISelectOption } from '@engineering11/ui-lib/e11-select'
import { EmployeeLocation, ExperienceLevel } from 'shared-lib'

export interface ISummaryCardDetail {
  label: string
  value: string
}
export interface ISummaryCard {
  title: string
  totalNumber: number
  icon?: string
  subtitle?: string
  detail: ISummaryCardDetail[]
}
export enum ReportJobStatus {
  Published = 'Published',
  Draft = 'Draft',
  ClosedFilled = 'Closed Filled',
  ClosedNotFilled = 'Closed Not Filled',
  Paused = 'Paused',
}
export const summaryList: ISummaryCard[] = [
  {
    title: 'Application',
    totalNumber: 100,
    subtitle: 'Timeframe',
    detail: [
      {
        label: 'First',
        value: 'Jan 10, 2024',
      },
      {
        label: 'Latest',
        value: 'Jan 10, 2024',
      },
    ],
  },
  {
    title: 'Total Hires',
    totalNumber: 63,
    subtitle: 'Time to Hire',
    detail: [
      {
        label: 'Average',
        value: '11 Days',
      },
      {
        label: 'Shortest',
        value: '3 Days',
      },
      {
        label: 'Longest',
        value: '3 Days',
      },
    ],
  },
]
export const experienceLevel = Object.values(ExperienceLevel).map(level => ({
  label: level,
  value: level,
}))
export const employeeLocation = Object.values(EmployeeLocation).map(level =>
  level === EmployeeLocation.InOffice
    ? {
        label: 'In Office',
        value: level,
      }
    : {
        label: level,
        value: level,
      }
)
export const dateTypes: ISelectOption[] = [
  {
    name: 'Created On',
    value: 'jobPostCreatedAt',
  },
  {
    name: 'Published On',
    value: 'publishedDate',
  },
  {
    name: 'Updated On',
    value: '__updatedAt',
  },
]
