import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  e11FieldInputCheckBox,
  e11FormFor,
  e11FormLayoutFullWidth,
} from '@engineering11/ui-lib/e11-form-builder'
import { ICheckboxOptions } from '@engineering11/ui-lib/e11-input-checkbox'
import { RequiredCandidateDetailKeys } from 'shared-lib'

type RequiredCandidateDetailsForm = Record<RequiredCandidateDetailKeys, boolean>

@Component({
  selector: 'required-contact-details-form',
  standalone: true,
  imports: [E11DynamicFormModule],
  template: `
    <div class="">
      <e11-dynamic-form-builder
        #requiredFieldsForm
        [fieldGroups]="fieldGroups"
        [hideSubmitMenu]="true"
        [loading]="loading"
        [initialValue]="_initialValue"
        (valueChanges)="onValueChanges($event)"
      >
      </e11-dynamic-form-builder>
    </div>
  `,
})
export class RequiredContactDetailsFormComponent {
  @ViewChild('requiredFieldsForm') requiredFieldsForm!: E11DynamicFormBuilderComponent<RequiredCandidateDetailsForm>
  _initialValue?: RequiredCandidateDetailsForm
  @Input() set initialValue(value: RequiredCandidateDetailKeys[] | undefined) {
    if (!Array.isArray(value)) return
    this._initialValue = fromKeysToObject(value)
  }
  @Input() loading: boolean = false

  @Output() valueChanges = new EventEmitter<RequiredCandidateDetailKeys[]>()

  fieldGroups = this.buildForm()

  buildForm() {
    return e11FormFor<RequiredCandidateDetailsForm>()([
      e11FormLayoutFullWidth([
        e11FieldInputCheckBox('lastName', '', this.createOption('Name', 'lastName'), undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: 'e11-block' },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputCheckBox('email', '', this.createOption('Email', 'email'), undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: 'e11-block' },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputCheckBox('phoneNumber', '', this.createOption('Phone', 'phoneNumber'), undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: 'e11-block' },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputCheckBox('address', '', this.createOption('Address', 'address'), undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: 'e11-block' },
        }),
      ]),
    ])
  }
  private createOption(label: string, value: keyof RequiredCandidateDetailsForm): ICheckboxOptions[] {
    return [{ name: label, value, checkboxProps: { containerClass: 'e11-mb-2' } }]
  }
  onValueChanges(formData: RequiredCandidateDetailsForm) {
    this.valueChanges.emit(extractRequiredDetailKeys(formData))
  }
}

function extractRequiredDetailKeys(obj: RequiredCandidateDetailsForm): RequiredCandidateDetailKeys[] {
  return obj ? (Object.keys(obj) as RequiredCandidateDetailKeys[]).filter(key => obj[key]) : []
}
function fromKeysToObject(value: string[]): RequiredCandidateDetailsForm {
  return value.reduce((acc: RequiredCandidateDetailsForm, key) => {
    acc[key as RequiredCandidateDetailKeys] = true
    return acc
  }, {} as RequiredCandidateDetailsForm)
}
