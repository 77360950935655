import { inject, Injectable } from '@angular/core'
import { IAutomationFormData } from '@employer/app/components/jobs-automation/automation-form.model'
import { AutomationTransform, IAutomationDTO } from '@employer/app/components/jobs-automation/automation.dto'
import { selectors } from '@employer/app/store/selectors'
import { environment } from '@employer/environments/environment'
import { IFileReceipt } from '@engineering11/files-web'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { FileUploadService } from 'projects/shared-lib/src/lib/service/file-upload.service'
import { filter, firstValueFrom, map, Observable, tap } from 'rxjs'

export type ISetAutomationReq = { jobPostContentId: string; formData: IAutomationFormData }

@Injectable({ providedIn: 'root' })
export class JobPostAutomationService {
  #logger = inject(E11Logger)
  #store = inject(Store)
  #restClient = new RestApiClient({
    baseUrl: environment.services.jobsAutomation,
    token: this.#store.select(selectors.getCurrentToken).pipe(filter(isNotNil)),
  })

  constructor(private readonly fileService: FileUploadService) {}

  getAutomationForJob(jobPostContentId: string): Observable<IAutomationFormData> {
    this.#logger.log('getAutomationForJobPost ', jobPostContentId)
    return this.#restClient.get<IAutomationDTO>(`jobs-automation/${jobPostContentId}`).pipe(
      tap(a => this.#logger.log('getAutomationForJobPost: ', a)),
      map(res => AutomationTransform.dtoToForm(res.data))
    )
  }

  async setAutomation(req: ISetAutomationReq) {
    this.#logger.log('JobPostAutomationService.setAutomation', req)
    const dto = AutomationTransform.formToDto(req.formData)
    const res = await firstValueFrom(this.#restClient.put(`jobs-automation/${req.jobPostContentId}`, dto).pipe(map(r => r.data)))
    this.#logger.log('JobPostAutomationService.setAutomation res', res)

    return req.formData
  }

  async uploadAttachments(files: File[]): Promise<IFileReceipt[]> {
    if (!files) return []
    const response = await this.fileService.uploadFiles({ files: files, makePublic: true })
    return response.map(r => r.fileReceipt)
  }

  async uploadAttachment(file: File): Promise<IFileReceipt> {
    const response = await this.fileService.uploadFile({ file: file, makePublic: true })
    return response.fileReceipt
  }
}
