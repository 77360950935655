import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { selectors } from '@employer/app/store/selectors'
import { environment } from '@employer/environments/environment'
import { CustomerService, IBusinessCustomer, ISocial } from '@engineering11/customer-web'
import { ReplaceValues } from '@engineering11/types'
import { Validation } from '@engineering11/ui-lib/e11-input-errors'
import { E11NotificationMessage, E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { isNotNil, MODERN_URL_REGEX } from '@engineering11/utility'
import { e11Error, E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { firstValueFrom, Subject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { FormCustomValidationService, LocalStorageService } from 'shared-lib'

type SocialForm = Required<ReplaceValues<ISocial, FormControl<string | null | undefined>>>

@Component({
  selector: 'social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class CustomerSocialMediaComponent implements OnInit, OnDestroy {
  customer?: IBusinessCustomer
  destroy$: Subject<boolean> = new Subject<boolean>()
  formSocial?: FormGroup<SocialForm>
  formSocialSubmitted = false
  linkToCareersPageBaseURL = environment.careersPageBaseLink
  linkToCareersPage: string = ''

  get f() {
    return this.formSocial!.controls as {
      [key: string]: FormControl
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private customValidator: FormCustomValidationService,
    private customerService: CustomerService,
    private localStorageService: LocalStorageService,
    private store: Store,
    private logger: E11Logger,
    private notificationsService: E11NotificationsService
  ) {}

  async ngOnInit(): Promise<void> {
    const customerKey$ = this.store.select(selectors.getCustomerKey$).pipe(filter(isNotNil))
    const customerKey = await firstValueFrom(customerKey$)

    const customer$ = this.customerService.get(customerKey)

    const customer = await firstValueFrom(customer$)
    if (!customer) throw e11Error({ type: 'customer/not-found', title: 'Customer not found' })

    this.customer = customer as IBusinessCustomer
    this.linkToCareersPage = `${this.linkToCareersPageBaseURL}?client=${this.customer.id}`
    this.createFormSocial()
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  createFormSocial(): void {
    this.logger.log('createFormSocial')

    const keyToFormControl = (key: keyof ISocial) =>
      new FormControl(
        {
          disabled: false,
          value: this.customer?.socialMedia ? this.customer.socialMedia[key] ?? null : null,
        },
        [Validators.pattern(MODERN_URL_REGEX), this.customValidator.whitespaceValidator()]
      )

    const urlKeys: (keyof ISocial)[] = ['facebookUrl', 'instagramUrl', 'linkedinUrl', 'snapchatUrl', 'tiktokUrl', 'xUrl']

    const formControlsObj: Partial<SocialForm> = {}
    urlKeys.forEach(key => {
      formControlsObj[key] = keyToFormControl(key)
    })

    this.formSocial = this.formBuilder.group(formControlsObj as SocialForm)
  }

  async submitFormSocial() {
    Validation.validateAll(this.formSocial!)
    if (this.formSocial?.valid && this.customer) {
      this.customer.socialMedia = { ...this.customer.socialMedia, ...this.formSocial.value }
      await this.customerService.update(this.customer!)

      const notification: E11NotificationMessage = {
        title: 'Success',
        message: 'Social media links updated successfully',
        type: 'success',
        autoClose: true,
        dismissOnRouteChange: true,
      }
      this.notificationsService.popNotificationMessage(notification)
    }
  }

  preview() {
    window.open(this.linkToCareersPage, '_blank')
  }
}
