import { Injectable } from '@angular/core'
import { BaseAccessConfigProvider, IAccessConfig } from '@engineering11/access-web'
import { CustomerStore } from '@engineering11/customer-web'
import { isDeepEqual, isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { ALL_ROLES, RoleDescriptionMap, RoleLabelMap } from '../role.constants'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class AccessConfigProvider extends BaseAccessConfigProvider {
  private roles$ = this.store.select(selectors.getCurrentRoles).pipe(filter(isNotNil), distinctUntilChanged(isDeepEqual))

  constructor(private store: Store, private customerStore: CustomerStore, private logger: E11Logger) {
    super()
  }

  get config(): IAccessConfig {
    return {
      roles$: this.roles$,
      products$: this.customerStore.currentCustomer$.pipe(map(c => c?.products ?? [])),
      userId$: this.store.select(selectors.getCurrentUserId),
      useLimitDefaults: {},
      roleDisplayConfig: { allRoles: ALL_ROLES, roleDescriptionMap: RoleDescriptionMap, roleLabelMap: RoleLabelMap },
    }
  }
}
