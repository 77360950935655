import { Injectable } from '@angular/core'
import { selectors } from '@employer/app/store/selectors'
import { environment } from '@employer/environments/environment'
import { ICustomerLocation } from '@engineering11/customer-web'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { filter, firstValueFrom, map } from 'rxjs'
import { DegreeLevel, EmployeeLocation, EmploymentType, IRequirableSkill } from 'shared-lib'

export interface FullJobGenerationResponse {
  jobDescription: string
  qualifications: string
  responsibilities: string
  skills: IRequirableSkill[]
  employmentType?: EmploymentType
  experienceLevel?: string
  educationLevel?: DegreeLevel
  employeeLocation?: EmployeeLocation
  locationAddress?: ICustomerLocation
  department?: string
}

export interface CustomJobGenerationRequest {
  jobTitle: string
  keywords?: string
  employmentType?: EmploymentType
  experienceLevel?: string
  educationLevel?: DegreeLevel
  employeeLocation?: EmployeeLocation
  locationAddress?: ICustomerLocation
  department?: string
}

@Injectable({ providedIn: 'root' })
export class JobPostGenerationService {
  private restApiClient = new RestApiClient({
    baseUrl: environment.services.jobs,
    token: this.store.select(selectors.getCurrentToken),
  })
  constructor(private store: Store, private errorHandler: E11ErrorHandlerService) {}

  generateAll(contentId: string): Promise<FullJobGenerationResponse> {
    const all$ = this.restApiClient.post<FullJobGenerationResponse>(`job-post-generation/${contentId}/all`, {}).pipe(
      filter(isNotNil),
      map(res => res.data)
    )

    return firstValueFrom(all$).catch(err => {
      throw this.handleError(err)
    })
  }

  generateCustom(request: CustomJobGenerationRequest): Promise<FullJobGenerationResponse> {
    const all$ = this.restApiClient.post<FullJobGenerationResponse>(`job-post-generation/custom`, request).pipe(
      filter(isNotNil),
      map(res => res.data)
    )

    return firstValueFrom(all$).catch(err => {
      throw this.handleError(err)
    })
  }

  generateJobDescription(contentId: string) {
    const jobDescription$ = this.restApiClient.post<{ jobDescription: string }>(`job-post-generation/${contentId}/description`, {}).pipe(
      filter(isNotNil),
      map(res => res.data.jobDescription.trim())
    )

    return firstValueFrom(jobDescription$).catch(err => {
      throw this.handleError(err)
    })
  }

  generateJobQualifications(contentId: string) {
    const qualifications$ = this.restApiClient.post<{ qualifications: string }>(`job-post-generation/${contentId}/qualifications`, {}).pipe(
      filter(isNotNil),
      map(res => res.data.qualifications.trim())
    )

    return firstValueFrom(qualifications$).catch(err => {
      throw this.handleError(err)
    })
  }

  generateJobResponsibilities(contentId: string) {
    const responsibilities$ = this.restApiClient.post<{ responsibilities: string }>(`job-post-generation/${contentId}/responsibilities`, {}).pipe(
      filter(isNotNil),
      map(res => res.data.responsibilities.trim())
    )

    return firstValueFrom(responsibilities$).catch(err => {
      throw this.handleError(err)
    })
  }

  generateJobSkills(contentId: string) {
    const skills$ = this.restApiClient.post<{ skills: IRequirableSkill[] }>(`job-post-generation/${contentId}/skills`, {}).pipe(
      filter(isNotNil),
      map(res => res.data.skills)
    )

    return firstValueFrom(skills$).catch(err => {
      throw this.handleError(err)
    })
  }

  private handleError(err: Error): Error {
    return this.errorHandler.handleError(err, { alertUser: true })
  }
}
