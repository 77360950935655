import { Injectable } from '@angular/core'
import { automationFormDefaults, IAutomationFormData } from '@employer/app/components/jobs-automation/automation-form.model'
import { JobsPostAutomationCompanySettingsService } from '@employer/app/modules/customer/views/jobs-post-automation-company-settings/jobs-post-automation-company-settings.service'
import { JobPostAutomationService } from '@employer/app/modules/jobs/views/job-automation/job-post-automation.service'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { NotificationHelper } from '@engineering11/web-ui-helpers'
import { ComponentStore } from '@ngrx/component-store'
import { tapResponse } from '@ngrx/operators'
import { finalize, from, mergeMap, Observable, switchMap } from 'rxjs'

// Using an entity store since IJobPostAutomationEventHandler is the principal entity we are concerned with for automation
export interface IJobPostAutomationStore {
  currentAutomationId?: string
  formData?: IAutomationFormData
  loaded: boolean
  saving: boolean
}

const initialState: IJobPostAutomationStore = {
  loaded: false,
  saving: false,
}

@Injectable({ providedIn: 'root' })
export class JobPostAutomationStore extends ComponentStore<IJobPostAutomationStore> {
  constructor(
    private jobPostAutomationService: JobPostAutomationService,
    private errorHandler: E11ErrorHandlerService,
    private notificationHelper: NotificationHelper,
    private jobPostAutomationCompanyService: JobsPostAutomationCompanySettingsService
  ) {
    super(initialState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)
  readonly formData$: Observable<IAutomationFormData> = this.select(s => s.formData ?? automationFormDefaults)
  readonly saving$ = this.select(s => s.saving)
  readonly loaded$ = this.select(s => s.loaded)

  // EFFECTS
  readonly onGetAutomationForJobPost = this.effect((jobPostId$: Observable<string>) =>
    jobPostId$.pipe(
      switchMap(jobPostId => {
        this.setLoaded(false)
        this.setCurrentAutomationId(jobPostId)
        return this.jobPostAutomationService.getAutomationForJob(jobPostId).pipe(
          tapResponse(
            res => this.setAutomation({ ...automationFormDefaults, ...res }),
            (err: Error) => this.errorHandler.handleError(err, { alertUser: true })
          ),
          finalize(() => this.setLoaded(true))
        )
      })
    )
  )

  readonly onSaveAutomation = this.effect((formData$: Observable<IAutomationFormData>) =>
    formData$.pipe(
      mergeMap(formData => {
        const id = this.get().currentAutomationId!
        this.setSaving(true)
        return from(this.jobPostAutomationService.setAutomation({ formData, jobPostContentId: id })).pipe(
          tapResponse(
            res => {
              this.notificationHelper.popNotification(savedSuccessNotification)
            },
            (err: Error) => this.errorHandler.handleError(err, { alertUser: true })
          ),
          finalize(() => this.setSaving(false))
        )
      })
    )
  )

  // UPDATERS

  private readonly setCurrentAutomationId = this.updater((s, currentAutomationId: string) => ({
    ...s,
    currentAutomationId,
  }))
  private readonly setAutomation = this.updater((s, formData: IAutomationFormData) => ({
    ...s,
    loaded: true,
    formData,
  }))

  private readonly setLoaded = this.updater((s, loaded: boolean) => ({ ...s, loaded }))
  private readonly setSaving = this.updater((s, saving: boolean) => ({ ...s, saving }))
}

const savedSuccessNotification: INotificationMessage = {
  type: 'success',
  title: 'Saved',
  message: 'Successfully saved automation',
}
