import { Injectable } from '@angular/core'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { ISwimlaneStatusUpdatedNotification } from 'shared-lib'
import { CandidateDetailTabs, EmployerNavigationService } from '../navigation.service'

@Injectable({ providedIn: 'root' })
export class SwimlaneStatusUpdatedHandler implements INotificationHandler<ISwimlaneStatusUpdatedNotification, INotificationView> {
  notificationType: string = 'employer_job_application_swimlane_changed'

  constructor(private userAppNotificationService: UserAppNotificationService, private navigationService: EmployerNavigationService) {}
  onClick(notification: ISwimlaneStatusUpdatedNotification): boolean | Promise<boolean> {
    this.markAsRead(notification)
    return this.navigationService.toCandidateDetailPage(notification.jobPostId, notification.candidateApplicationId, CandidateDetailTabs.Application)
  }

  private markAsRead(notification: ISwimlaneStatusUpdatedNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
