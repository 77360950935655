import { Injectable } from '@angular/core'
import { environment } from '@employer/environments/environment'
import { EventsClient } from '@engineering11/events-web'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { filter, firstValueFrom } from 'rxjs'
import { LocalStorageService } from 'shared-lib'
import { EmployerEvents } from '../models/server-events.model'
import { selectors } from '../store/selectors'
const APPLICATION_VIEWED_EVENT = 'candidateApplicationViewed'

@Injectable({ providedIn: 'root' })
export class EventsService extends EventsClient<EmployerEvents> {
  userId$ = this.store.select(selectors.getCurrentUserId).pipe(filter(isNotNil))
  constructor(readonly tokenStorageService: LocalStorageService, private store: Store) {
    super({
      token: () => tokenStorageService.getAccessToken()!,
      eventsClientUrl: environment.services.events,
    })
  }

  async sendApplicationViewedEvent(applicationId: string) {
    const userId = await firstValueFrom(this.userId$)
    return this.sendEvent({
      eventType: APPLICATION_VIEWED_EVENT,
      applicationId,
      employerUserId: userId,
    })
  }
}
