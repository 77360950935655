import { Injectable } from '@angular/core'
import { environment } from '@employer/environments/environment'
import {
  BaseConversationCustomDataProvider,
  IConversationImageData,
  IConversationVMNoCustomData,
  MessagingConfigurationProvider,
  MessagingMappingConfig,
} from '@engineering11/messaging-web'
import { Store } from '@ngrx/store'
import { ConversationTypes, IApplicationConversationMetadata, ICandidatePoolConversationMetadata, MessagingSystemUsers } from 'shared-lib'
import { selectors } from '../store/selectors'

export const MESSAGING_EDITOR_CONFIG = {
  attachmentMaxFileSize: 300,
} as const
@Injectable({ providedIn: 'root' })
export class MessagingConfigurationStore {
  constructor(private store: Store) {}

  getConfig() {
    return {
      baseUrl: environment.services.messaging,
      baseRoute: '/c/m',
      token$: this.store.select(selectors.getCurrentToken),
      userId$: this.store.select(selectors.getCurrentUserId),
      systemUsers: MessagingSystemUsers,
      messagingEditorConfig: {
        attachmentMaxFileSize: MESSAGING_EDITOR_CONFIG.attachmentMaxFileSize,
      },
    }
  }
}
@Injectable({ providedIn: 'root' })
export class MessagingConfigFromApp implements MessagingConfigurationProvider {
  constructor(private configStore: MessagingConfigurationStore) {}

  get config(): MessagingMappingConfig {
    return this.configStore.getConfig()
  }
}

@Injectable({ providedIn: 'root' })
export class ConversationCustomDataProvider extends BaseConversationCustomDataProvider {
  conversationName(conversation: IConversationVMNoCustomData<IApplicationConversationMetadata | ICandidatePoolConversationMetadata>): string {
    switch (conversation.type as ConversationTypes) {
      case ConversationTypes.JobApplication:
        const candidateId = conversation.clientMetadata?.candidateId
        if (!candidateId) return super.conversationName(conversation)
        const candidateName = conversation.participantInformation[candidateId].displayName
        return `${candidateName} and Hiring Team`
      default:
        return super.conversationName(conversation)
    }
  }

  conversationShortName(conversation: IConversationVMNoCustomData<IApplicationConversationMetadata | ICandidatePoolConversationMetadata>): string {
    switch (conversation.type as ConversationTypes) {
      case ConversationTypes.JobApplication:
        const candidateId = conversation.clientMetadata?.candidateId
        if (!candidateId) return super.conversationShortName(conversation)
        const candidateName = conversation.participantInformation[candidateId].displayName
        return candidateName
      default:
        return super.conversationShortName(conversation)
    }
  }

  conversationImageData(
    conversation: IConversationVMNoCustomData<IApplicationConversationMetadata | ICandidatePoolConversationMetadata>
  ): IConversationImageData {
    switch (conversation.type as ConversationTypes) {
      case ConversationTypes.JobApplication:
        const candidateId = conversation.clientMetadata?.candidateId
        if (!candidateId) return super.conversationImageData(conversation)
        const candidateInfo = conversation.participantInformation[candidateId]
        return super.imageDataFromParticipant(candidateInfo)
      default:
        return super.conversationImageData(conversation)
    }
  }

  conversationSubtitle(conversation: IConversationVMNoCustomData): string {
    return conversation.clientMetadata ? conversation.clientMetadata['jobPostName'] : ''
  }
}
