import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { ReportFilteringNavComponent } from '../nav-components/report-filtering-nav/report-filtering-nav.component'

@Component({
  selector: 'reports-extended-nav',
  standalone: true,
  imports: [CommonModule, ReportFilteringNavComponent],
  template: `
    <h3>Reports</h3>
    <report-filtering-nav></report-filtering-nav>
  `,
})
export class ReportsExtendedNavComponent {}
