import { IContentAssociation, IContentDocument } from '@engineering11/content-web'
import { APPLICATION_STATE, IEmployerVideoContent, IJobNotificationSettings, JobPostIntegrations, RequiredCandidateDetailKeys } from 'shared-lib'
import { IJobProfile, IJobProfileContentItem } from './job-profile.model'

export const CONTENT_TYPE_JOB_POST = 'job-post'

type Modify<T, R> = Omit<T, keyof R> & R

export enum JobPostStatus {
  Published = 'Published',
  Draft = 'Draft',
  ClosedFilled = 'ClosedFilled',
  ClosedNotFilled = 'ClosedNotFilled',
  Deleted = 'Deleted',
}

export const JOB_CLOSED_STATUSES = [JobPostStatus.ClosedFilled, JobPostStatus.ClosedNotFilled]

export enum JobPostContext {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum JobPostAutomationStatus {
  NotConfigured = 'NotConfigured',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export interface IJobCloseSchedule {
  message: string
  senderId: string
  closeDate: Date
  messageDuration: Duration
  closeTaskId?: string
  messageTaskId?: string
}

export interface IJobPost extends Modify<IJobProfile, { status: JobPostStatus }> {
  sendToGoogle?: boolean
  hideFromCareerSite?: boolean
  editorIds?: string[]
  integrations?: Array<JobPostIntegrations>
  deactivated?: Date
  video?: IEmployerVideoContent | null
  automationStatus?: JobPostAutomationStatus
  jobPaused?: boolean
  jobCloseSchedule?: IJobCloseSchedule
  requiredFields?: RequiredCandidateDetailKeys[]
  jobNotificationSettings?: IJobNotificationSettings
}

export interface IPublishedJobPost extends IJobPost {
  totalApplicants?: number
  inactiveApplicants?: number
  unresponsiveApplicants?: number
}

export interface IJobPostContentAssociation extends IContentAssociation {
  swimlanes?: APPLICATION_STATE[]
}

export type IJobPostContentItem = Modify<
  IJobProfileContentItem,
  {
    document: IJobPost
    associations: Map<string, IJobPostContentAssociation>
  }
>

export function isJobPost(document: IContentDocument): document is IJobPost {
  return document.contentType === CONTENT_TYPE_JOB_POST
}

export const jobPostStatusMappings: Record<JobPostStatus, string> = {
  [JobPostStatus.Draft]: 'Draft',
  [JobPostStatus.Published]: 'Published',
  [JobPostStatus.ClosedFilled]: 'Closed: Filled',
  [JobPostStatus.ClosedNotFilled]: 'Closed: Not Filled',
  [JobPostStatus.Deleted]: 'Paused',
}
