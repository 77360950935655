import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequiredCandidateDetailKeys } from 'shared-lib'

@Component({
  selector: 'required-contact-details',

  template: `
    <div class="e11-w-full e11-mb-6 e11-items-start">
      <div class="e11-grow e11-mb-2">
        <h3 class="e11-font-bold e11-mb-0">{{ 'Required Candidate Details' | translate }}</h3>
        <p class="e11-mb-0 e11-text-sm">{{ 'Select which details candidates must provide when applying for this job.' | translate }}</p>
      </div>
      <div class="">
        <required-contact-details-form
          [initialValue]="requiredFields"
          [loading]="loading"
          (valueChanges)="valueChanges.emit($event)"
        ></required-contact-details-form>
      </div>
    </div>
  `,
})
export class RequiredContactDetailsComponent {
  @Input() requiredFields?: RequiredCandidateDetailKeys[]
  @Input() loading: boolean = false

  @Output() valueChanges = new EventEmitter<RequiredCandidateDetailKeys[]>()
}
