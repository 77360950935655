import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'

@Component({
  selector: 'single-job-filtering',
  standalone: true,
  imports: [CommonModule, E11DividerModule],
  template: `
    <div *ngIf="isSingleJobPostRoute">
      <div>
        @for (item of sectionList; track $index) {
        <p
          class=" e11-text-sm e11-font-semibold e11-mb-1 e11-p-2 e11-cursor-pointer e11-text-skin-primary-accent hover:e11-bg-skin-primary-accent/20"
          (click)="scrollTo.emit(item.id)"
        >
          {{ item.label }}
        </p>
        }
      </div>
    </div>
  `,
})
export class SingleJobFilteringComponent {
  @Input() isSingleJobPostRoute: boolean = false

  @Output() scrollTo = new EventEmitter<string>()
  sectionList = [
    {
      label: 'Swimlanes Counts',
      id: 'swimlanes-counts',
    },
    {
      label: 'Application Timeline',
      id: 'application-timeline',
    },
    {
      label: 'Candidate Engagement',
      id: 'candidate-engagement',
    },
  ]
}
