import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ICareersPageReportingFilter } from '@employer/app/modules/reports/reports.interface'
import { ReportNavService } from '@employer/app/services/report-nav.service'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import {
  E11DynamicFormBuilderComponent,
  E11DynamicFormModule,
  e11FieldInputDatePicker,
  e11FormFor,
  e11FormLayoutFullWidth,
} from '@engineering11/ui-lib/e11-form-builder'
import { format } from 'date-fns'
import { Subscription } from 'rxjs'

@Component({
  selector: 'careers-page-filtering',
  standalone: true,
  imports: [E11DynamicFormModule, E11ButtonModule],
  template: `
    <e11-dynamic-form-builder
      #formRef
      [fieldGroups]="form"
      [initialValue]="initialValue"
      [hideSubmitMenu]="true"
      (valueChanges)="valueChanges($event)"
    ></e11-dynamic-form-builder>
    <e11-button class="e11-flex e11-justify-center" [ghost]="true" [size]="'sm'" (click)="resetFilters()" value="Reset Filters"></e11-button>
  `,
})
export class CareersPageFilteringComponent implements OnInit, OnDestroy {
  @ViewChild(E11DynamicFormBuilderComponent) formRef!: E11DynamicFormBuilderComponent<ICareersPageReportingFilter>

  form = e11FormFor<ICareersPageReportingFilter>()([
    e11FormLayoutFullWidth([
      e11FieldInputDatePicker('startDate', 'Start Date', undefined, {
        validatorOrOpts: [],
        extraInputOption: {
          maxYearSelectable: new Date().getFullYear(),
          classOverride: 'e11-border-skin-primary-accent e11-text-skin-primary-accent e11-mb-4',
          showLeadingIcon: true,
          showTrailingIcon: true,
          inputMessage: 'Select a start date',
        },
      }),
    ]),
    e11FormLayoutFullWidth([
      e11FieldInputDatePicker('endDate', 'End Date', undefined, {
        validatorOrOpts: [],
        extraInputOption: {
          maxYearSelectable: new Date().getFullYear(),
          classOverride: 'e11-border-skin-primary-accent e11-text-skin-primary-accent e11-mb-4',
          showLeadingIcon: true,
          showTrailingIcon: true,
          inputMessage: 'Select a end date',
        },
      }),
    ]),
  ])

  initialValue: ICareersPageReportingFilter | undefined
  routeSub: Subscription | undefined
  constructor(private readonly reportNavService: ReportNavService, private readonly router: Router, private readonly route: ActivatedRoute) {}

  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe(params => {
      const startDate = params['startDate']
      const endDate = params['endDate']
      if (startDate || endDate) {
        this.initialValue = {
          startDate: startDate,
          endDate: endDate,
        }
        this.valueChanges(this.initialValue)
      }
    })
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe()
  }

  valueChanges(event: Record<keyof ICareersPageReportingFilter, string | undefined>) {
    if (!event.endDate && !event.startDate) {
      return
    }

    const startDate = event.startDate ? new Date(event.startDate).toISOString() : undefined
    const endDate = event.startDate && !event.endDate ? new Date().toISOString() : event.endDate

    const formattedStartDate = startDate ? format(new Date(startDate), 'yyyy-MM-dd') : undefined
    const formattedEndDate = endDate ? format(new Date(endDate), 'yyyy-MM-dd') : undefined

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      queryParamsHandling: 'merge',
    })

    this.reportNavService.setCareersPageDateFilter({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    })
  }

  resetFilters() {
    this.formRef.form.reset({
      startDate: undefined,
      endDate: undefined,
    })

    // Remove date filter parameters from URL
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        startDate: null,
        endDate: null,
      },
      queryParamsHandling: 'merge',
    })
  }
}
