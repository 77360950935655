import { Injectable } from '@angular/core'
import { JobPostApplicationRepository } from '@employer/app/repositories/job-post-application.repository'
import { EventsService } from '@employer/app/services/events.service'
import { selectors } from '@employer/app/store/selectors'
import { environment } from '@employer/environments/environment'
import { AtLeast } from '@engineering11/types'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'
import { APPLICATION_STATE, ARCHIVED_STATES, IJobPostApplication, IRejectionReason, LocalStorageService } from 'shared-lib'

export interface CandidateApplicationStatusUpdate extends IRejectionReason {
  jobPostId: string
  applicationId: string
  // candidateId: string
  status: APPLICATION_STATE
  previousStatus?: APPLICATION_STATE
}

export type CandidateApplicationStatusUpdateResponse = AtLeast<IJobPostApplication, 'jobPostId' | 'id' | 'applicationState'>

@Injectable({ providedIn: 'root' })
export class CandidateApplicationSwimlaneService {
  userId$ = this.store.select(selectors.getCurrentUserId)
  private restClient: RestApiClient

  constructor(
    private repository: JobPostApplicationRepository,
    private eventsService: EventsService,
    private store: Store,
    tokenStorage: LocalStorageService
  ) {
    this.restClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: () => tokenStorage.getAccessToken()!,
    })
  }

  /**
   * Sets previousStatus to be previousApplicationState on the record - this should be the state of the current application, before the update
   */
  async updateApplicationStatus(request: CandidateApplicationStatusUpdate) {
    const { jobPostId, applicationId, status, previousStatus, otherInputString, rejectionReason } = request
    let eventResult$: Promise<any>
    const employerUserId = await firstValueFrom(this.userId$)

    if (status === APPLICATION_STATE.NOT_INTERESTED) {
      eventResult$ = this.eventsService.sendEvent({ eventType: 'notInterestedEmployer', applicationId, employerUserId })
    } else if (status === APPLICATION_STATE.HIRED) {
      eventResult$ = firstValueFrom(this.repository.get(jobPostId, applicationId)).then(item => {
        if (item) {
          return this.eventsService.sendEvent({
            eventType: 'candidateHired',
            applicationId,
            employerUserId,
            external: false,
            candidateId: item.candidateId,
          })
        }
        return this.eventsService.sendEvent({ eventType: 'candidateHired', applicationId, employerUserId, external: true })
      })
    } else if (status === APPLICATION_STATE.OFFER_SENT) {
      eventResult$ = this.eventsService.sendEvent({ eventType: 'offerSent', applicationId, employerUserId })
    } else {
      eventResult$ = Promise.resolve()
    }

    const updatePromise$ = firstValueFrom(
      this.restClient
        .put<CandidateApplicationStatusUpdateResponse>(`/job-posts/${jobPostId}/job-post-applications/${applicationId}`, {
          applicationState: status,
          rejectionReason,
          otherInputString,
        })
        .pipe(map(item => item.data))
    )
    const [eventResult, updateResult] = await Promise.all([eventResult$, updatePromise$])

    return updateResult
  }

  /**
   * Sets the new status to be what was passed in as previousStatus - when reactivating an application, we want it to return to its initial state before rejection
   */
  async reactivateApplication(
    reactivateRequest: Omit<CandidateApplicationStatusUpdate, 'status'>
  ): Promise<CandidateApplicationStatusUpdateResponse> {
    const employerUserId = await firstValueFrom(this.userId$)
    const { applicationId, jobPostId, previousStatus } = reactivateRequest
    this.eventsService.sendEventAsync({
      eventType: 'applicationReactivated',
      applicationId,
      employerUserId,
    })
    const status = previousStatus && !ARCHIVED_STATES.includes(previousStatus) ? previousStatus : APPLICATION_STATE.APPLIED
    return this.updateApplicationStatus({ applicationId, jobPostId, status })
  }
}
