import { Injectable } from '@angular/core'
import { Firestore } from '@angular/fire/firestore'
import { Optional } from '@engineering11/types'
import { uniq } from 'lodash'
import { BehaviorSubject, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { AutocompleteType, IAutocomplete } from './autocomplete.model'
import { AutoCompleteRepository } from './autocomplete.repository'

@Injectable({ providedIn: 'root' })
export class AutocompleteService {
  constructor(private repository: AutoCompleteRepository, private fs: Firestore) {}

  // get(id: string): Observable<IAutocomplete> {
  //   return this.repository.get(id)
  // }

  // update(model: Partial<IAutocomplete>): Promise<void> {
  //   return this.repository.update(model)
  // }

  // getAll(customerKey: string): Observable<IAutocomplete[]> {
  //   return this.repository.getAllByCustomer(customerKey)
  // }

  // getAllValueChange(customerKey: string): Observable<IAutocomplete[]> {
  //   return this.repository.getAllValueChangesByCustomer(customerKey)
  // }

  queryAutoComplete(customerKey: string, type: AutocompleteType, searchText: BehaviorSubject<string>): Observable<string[]> {
    return searchText.pipe(
      map(text => text.toLowerCase()), // We search by the lower case searchText
      switchMap((text: string) => {
        return this.repository.queryAutocompleteByCustomer(customerKey, type, text)
      }),
      map(autocompleteArray => autocompleteArray.map(autocomplete => autocomplete.name)),
      map(names => uniq(names))
    )
  }

  /**
   * Create a new record for future use in autocomplete
   * TODO: Determine how to prevent duplicates
   * @param customerKey customer to save it under
   * @param type type of record to autocomplete on
   * @param name text of record to complete with
   * @returns
   */
  add(customerKey: string, type: AutocompleteType, name: string) {
    const model: Optional<IAutocomplete, 'id'> = { customerKey, type, name, search: name.toLowerCase(), useCount: 1 }
    return this.repository.add(model)
  }

  increment(id: string) {
    return this.repository.increment(id)
  }

  // queryAutoComplete(customerKey: string, start: BehaviorSubject<string>): Observable<IAutocomplete[]> {
  //   return start.pipe(
  //     switchMap((startText: string) => {
  //       startText = startText.toLocaleLowerCase()
  //       const endText = startText + '\uf8ff'
  //       //TODO: Fix FirestoreQueryBuilder startAt and endAt and switch to that
  //       const queryFn = (ref: any) => ref.where('customerKey', '==', customerKey).orderBy('search').startAt(startText).endAt(endText).limit(10)
  //       return this.repository.queryValueChanges(queryFn)
  //     })
  //   )
  // }

  // /**
  //  * If the document does not exist, it will be created.
  //  *
  //  * @param model
  //  * @returns
  //  */
  // set(model: IAutocomplete): Promise<string> {
  //   return this.repository.set(model)
  // }
}
