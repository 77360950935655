import { IEmployerUser } from '@cnect/user-shared'
import * as fromReducers from '@employer/app/store/reducers'
import { createFeatureSelector, createSelector } from '@ngrx/store'

export const getCustomerStore = createFeatureSelector<fromReducers.customer.State>('customer')

export const getCustomerEntities = createSelector(getCustomerStore, fromReducers.customer.customerEntitySelectors.selectAll)

export const getCustomerDepartmentEntities = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.departments)
export const getCustomerLocationEntities = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.locations)
export const getCustomerUserEntities = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.users)
export const loading = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.loading)
export const getCustomerUserById = (authId: string) =>
  createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.users.find(user => user.authId === authId))
export const getCustomerUserMapById = createSelector(
  getCustomerStore,
  (store: fromReducers.customer.State) =>
    store.users.reduce((acc, nxt) => {
      return { ...acc, [nxt.id!]: nxt }
    }, {}) as Record<string, IEmployerUser>
)
export const getCustomerLoadingStatus = createSelector(getCustomerStore, (store: fromReducers.customer.State) => store.loading)
