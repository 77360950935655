import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { ReportNavService } from '@employer/app/services/report-nav.service'
import { IReportFilteringFormConfig, SDKReportFilteringComponent, dateBetween } from '@engineering11/reporting-web'
import {
  e11FieldInputDatePicker,
  e11FieldInputMultiSelect,
  e11FieldInputSelect,
  e11FormFor,
  e11FormLayoutFullWidth,
} from '@engineering11/ui-lib/e11-form-builder'
import { E11MultiSelectModule, IMultiSelectOption, mapToMultiselectOptions } from '@engineering11/ui-lib/e11-multi-select'
import { FilterNode, conditions, where } from '@parse-it/database'
import { employmentTypeMappings } from 'shared-lib'
import { IReportFilteringForm } from '../report-filtering-nav.component'
import { ReportJobStatus, dateTypes, employeeLocation, experienceLevel } from '../report-filtering.model'
@Component({
  selector: 'report-filter-form',
  standalone: true,
  imports: [CommonModule, E11MultiSelectModule, SDKReportFilteringComponent],
  template: `
    <div *ngIf="!isSingleJobPostRoute">
      <!-- report filtering form sdk -->
      <sdk-report-filtering [form]="form" [loading]="loading" [filterConfig]="filterConfig" [label]="'Job Post Date Range'"> </sdk-report-filtering>
    </div>
  `,
})
export class ReportFilterFormComponent implements OnInit, OnChanges {
  @Input() location: IMultiSelectOption[] = []
  @Input() cityOptions?: IMultiSelectOption[]
  @Input() department: IMultiSelectOption[] = []
  @Input() loading: boolean = false
  @Input() isSingleJobPostRoute: boolean = false
  filterConfig: IReportFilteringFormConfig<IReportFilteringForm> = {}
  form = this.buildFilterForm()

  employmentType: IMultiSelectOption[] = []
  experienceLevel = experienceLevel
  employeeLocation = employeeLocation
  jobPostStatus = mapToMultiselectOptions(
    Object.entries(ReportJobStatus).map(([key, value]) => ({ key, value })),
    d => d.value,
    d => d.key
  )
  constructor(private reportNavService: ReportNavService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location || changes.department) {
      this.form = this.buildFilterForm()
    }
    if (changes.dateType) {
      this.filterConfig = this.getFilterConfig()
    }
  }
  getEmploymentType() {
    return employmentTypeMappings.forEach((key, value) => this.employmentType.push({ label: key, value: value }))
  }
  ngOnInit(): void {
    this.getEmploymentType()
    this.filterConfig = this.getFilterConfig()
  }
  getFilterConfig() {
    return {
      valueChange: {
        transform: (props: IReportFilteringForm) => {
          return [
            ...this.jobStatusCondition(props['jobStatus']),
            ...this.addConditionIfNotEmpty(props['locationAddress'], () =>
              this.createJsonValueCondition('locationAddress', '$.name', props['locationAddress'])
            ),
            ...this.addConditionIfNotEmpty(props['city'], () => this.createJsonValueCondition('locationAddress', '$.city', props['city'])),
            ...this.addConditionIfNotEmpty(props['department'], () => this.createInCondition('department', props['department'])),
            ...this.addConditionIfNotEmpty(props['employmentType'], () => this.createInCondition('employmentType', props['employmentType'])),
            ...this.addConditionIfNotEmpty(props['experienceLevel'], () => this.createInCondition('experienceLevel', props['experienceLevel'])),
            ...this.addConditionIfNotEmpty(props['employeeLocation'], () => this.createInCondition('employeeLocation', props['employeeLocation'])),
            ...this.addDateCondition(props['dateType'], props['startDate'], props['endDate']),
          ]
        },
      },
    }
  }
  createInCondition(column: string, value: string[]) {
    return conditions([{ column, operator: 'IN', value }].filter(v => v.value))
  }
  createJsonValueCondition(baseColumn: string, jsonPath: string, value: string[]) {
    return conditions(
      [
        {
          column: `JSON_VALUE(${baseColumn},'${jsonPath}')`,
          operator: 'IN',
          value,
        },
      ].filter(v => v.value)
    )
  }

  addConditionIfNotEmpty(props: string[], conditionFn: () => any) {
    return props && props.length > 0 ? [conditionFn()] : []
  }
  addDateCondition(dateType: string, startDate?: string, endDate?: string) {
    this.reportNavService.updateReportDateType(dateType)
    if (!startDate && !endDate) return []
    const dateValues = [
      ...[startDate ? new Date(startDate).toISOString() : new Date().toISOString()],
      ...[endDate ? new Date(endDate).toISOString() : new Date().toISOString()],
    ]
    return [dateBetween(`STRING(timestamp(timestamp_seconds(INT64(${dateType}._seconds))))`, dateValues)]
  }

  jobStatusCondition(status?: string[]) {
    if (!status || status.length === 0) return []
    const isJobPaused = status.includes('Paused')
    const isPublished = status.includes('Published')

    if (isJobPaused && isPublished) return this.addConditionIfNotEmpty(status, () => this.createInCondition('status', status))
    if (isJobPaused && !isPublished) {
      return [
        conditions(
          [
            { column: 'jobPaused', operator: '=', value: true },
            { column: 'status', operator: 'IN', value: status ?? [] },
          ],
          'OR'
        ),
      ]
    } else if (isPublished && !isJobPaused) {
      return [publishedStatusCondition(status)]
    } else return this.addConditionIfNotEmpty(status, () => this.createInCondition('status', status))
  }
  buildFilterForm() {
    return e11FormFor<IReportFilteringForm>()([
      e11FormLayoutFullWidth(
        [
          e11FieldInputSelect('dateType', 'Date Type', dateTypes, 'jobPostCreatedAt', {
            validatorOrOpts: [],
            hideInputError: true,
          }),
        ],
        {
          fieldsClass: 'e11-mb-2',
          templateSpec: [
            {
              templateKey: 'dateRangeLabel',
              templatePosition: 'top',
            },
          ],
        }
      ),
      e11FormLayoutFullWidth([
        e11FieldInputDatePicker('startDate', 'Start Date', undefined, {
          validatorOrOpts: [],
          extraInputOption: {
            maxYearSelectable: new Date().getFullYear(),
            classOverride: 'e11-border-skin-primary-accent e11-text-skin-primary-accent e11-mb-4',
            showLeadingIcon: true,
            showTrailingIcon: true,
          },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputDatePicker('endDate', 'End Date', undefined, {
          validatorOrOpts: [],
          extraInputOption: {
            maxYearSelectable: new Date().getFullYear(),
            classOverride: 'e11-border-skin-primary-accent e11-text-skin-primary-accent e11-mb-4',
            showLeadingIcon: true,
            showTrailingIcon: true,
          },
        }),
      ]),
      e11FormLayoutFullWidth(
        [
          e11FieldInputMultiSelect('jobStatus', 'Status', this.jobPostStatus, undefined, {
            validatorOrOpts: [],
            hideInputError: true,
            extraInputOption: { isDropdownSelect: false, showDropdown: true, dropdownContainerClass: 'e11-border-skin-grey e11-relative' },
          }),
        ],
        {
          templateSpec: [
            {
              templateKey: 'divider',
              templatePosition: 'bottom',
            },
          ],
        }
      ),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('locationAddress', 'Location', this.location, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('city', 'City', this.cityOptions ?? [], undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('department', 'Department', this.department, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('employmentType', 'Employment Type', this.employmentType, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('experienceLevel', 'Experience Level', this.experienceLevel, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
      e11FormLayoutFullWidth([
        e11FieldInputMultiSelect('employeeLocation', 'Employee Location', this.employeeLocation, undefined, {
          validatorOrOpts: [],
          extraInputOption: { containerClass: "'e11-mb-4'", searchable: true },
        }),
      ]),
    ])
  }
}
export function publishedStatusCondition(status?: string[]): FilterNode {
  const filterNode: FilterNode = {
    type: 'filter',
    operator: 'AND',
    conditions: [
      where(
        [
          conditions(
            [
              { column: 'jobPaused', operator: '=', value: false },
              { column: 'jobPaused', operator: 'IS', value: null },
            ],
            'OR'
          ),
        ],
        'OR'
      ),
      status
        ? conditions([{ column: 'status', operator: 'IN', value: status }])
        : conditions([{ column: 'status', operator: '=', value: 'Published' }]),
    ],
  }
  return filterNode
}
