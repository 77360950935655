import { Injectable } from '@angular/core'
import { map, Observable, switchMap, tap } from 'rxjs'

import { automationFormDefaults } from '@employer/app/components/jobs-automation/automation-form.model'
import { selectors } from '@employer/app/store/selectors'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { filter } from 'rxjs/operators'
import { ICompanyAutomationFormData, JobPostAutomationCompanyRepository } from './job-post-automation-company.repository'

@Injectable({
  providedIn: 'root',
})
export class JobsPostAutomationCompanySettingsService {
  constructor(private store: Store, private jobPostAutomationCustomerRepository: JobPostAutomationCompanyRepository, private logger: E11Logger) {}

  private getCustomerAutomationConfig(customerKey: string): Observable<ICompanyAutomationFormData> {
    return this.jobPostAutomationCustomerRepository.get(customerKey).pipe(
      tap(a => this.logger.log('getAutomationForCustomer: ', a)),
      map(res => (res ? res : automationFormDefaults))
    )
  }

  getCurrentCustomerAutomationConfig(): Observable<ICompanyAutomationFormData> {
    return this.store.select(selectors.getCustomerKey).pipe(
      filter(isNotNil),
      tap(customerKey => this.logger.log('Get automation config for: ', customerKey)),
      switchMap(customerKey => this.getCustomerAutomationConfig(customerKey as string))
    )
  }

  async setAutomation(formData: ICompanyAutomationFormData) {
    this.logger.log('JobPostAutomationCompanySettingsService.setAutomation', formData)

    const result = await this.jobPostAutomationCustomerRepository.upsert(formData)
    this.logger.log('JobPostAutomationCompanySettingsService.setAutomation result', result)
    return result
  }
}
