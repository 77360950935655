import { Injectable } from '@angular/core'
import { canActivate, redirectLoggedInTo } from '@angular/fire/auth-guard'
import { Router } from '@angular/router'
import { IAuthService } from '@engineering11/auth-web'
import { TokenStorage } from 'shared-lib'

const redirectLoggedInToItems = () => redirectLoggedInTo(['/'])

@Injectable({
  providedIn: 'root',
})
export class DenyGuard {
  constructor(public authService: IAuthService, public router: Router, public tokenStorage: TokenStorage) {}

  canActivate(): any {
    return canActivate(redirectLoggedInToItems)
  }
}
