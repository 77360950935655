import { e11FormFor, e11LayoutGroup, e11FormLayoutFullWidth, e11FieldInputSwitch } from '@engineering11/ui-lib/e11-form-builder'
import { INotificationConfig } from '../../../../../../../shared-lib/src/lib/model/job-application-notification-settings.model'

export function buildNotificationConfigForm() {
  return e11FormFor<INotificationConfig>()([
    e11LayoutGroup(
      [
        e11FormLayoutFullWidth([
          e11FieldInputSwitch('emailNotification', 'Email Notifications', undefined, {
            extraInputOption: {
              color: 'success',
              size: 'xs',
            },
            hideInputError: true,
          }),
        ]),
        e11FormLayoutFullWidth([
          e11FieldInputSwitch('inAppNotification', 'In-app Notifications', undefined, {
            extraInputOption: {
              color: 'success',
              size: 'xs',
            },
            hideInputError: true,
          }),
        ]),
        e11FormLayoutFullWidth(
          [
            e11FieldInputSwitch('pushNotification', 'Push Notifications', undefined, {
              extraInputOption: {
                color: 'success',
                size: 'xs',
              },
              hideInputError: true,
            }),
          ],
          {
            templateSpec: [
              {
                templateKey: 'mobileTemplate',
                templatePosition: 'left',
              },
            ],
            containerClass: 'e11-flex e11-flex-row-reverse e11-justify-end e11-gap-2',
          }
        ),
      ],
      {
        layoutContainerClass: 'e11-flex e11-flex-col e11-gap-4',
      }
    ),
  ])
}
