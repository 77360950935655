import { transferArrayItem } from '@angular/cdk/drag-drop'
import { Injectable } from '@angular/core'
import { deepCopy, isNotNil } from '@engineering11/utility'
import { Timestamp } from '@engineering11/types'
import { ComponentStore } from '@ngrx/component-store'
import { groupBy, uniqBy } from 'lodash'
import { filter, map, Observable, switchMap, tap } from 'rxjs'
import { APPLICATION_STATE, IJobPostApplication } from 'shared-lib'
import { CandidateApplicationStatusUpdateResponse } from '../services/candidate-application-swimlane.service'
import { JobPostApplicationService } from '../services/job-post-application.service'

interface UpdateHitsByStatusPayload {
  status: APPLICATION_STATE
  hits: Timestamp<IJobPostApplication>[]
  total: number
  isFirstPage: boolean
}

interface TransferHitsByStatusPayload {
  targetId: string
  currentStatus: APPLICATION_STATE
  targetStatus: APPLICATION_STATE
}

export interface JobApplicationState {
  selectedGridStatus: APPLICATION_STATE | 'ALL'
  swimlaneHits: { [key in APPLICATION_STATE]: { hits: Timestamp<IJobPostApplication>[]; total: number } }
  gridHits: Timestamp<IJobPostApplication>[]
  applications: Timestamp<IJobPostApplication>[]
  loadingApplications: boolean
}

const defaultState: JobApplicationState = {
  selectedGridStatus: APPLICATION_STATE.APPLIED,
  swimlaneHits: {} as any, // using any to avoid having to define all the APPLICATION_STATE keys
  gridHits: [],
  applications: [],
  loadingApplications: true,
}

@Injectable({
  providedIn: 'root',
})
export class CandidateListStore extends ComponentStore<JobApplicationState> {
  constructor(private jobPostApplicationService: JobPostApplicationService) {
    super(defaultState)
  }

  // Selectors
  readonly getState = this.select(s => s)
  readonly applications$ = this.select(s => s.applications)
  readonly swimlaneHits$ = this.select(s => s.swimlaneHits)
  readonly gridHits$ = this.select(s => s.gridHits)
  readonly loadingApplications$ = this.select(s => s.loadingApplications)
  // Effects
  readonly onGetApplications = this.effect((payload$: Observable<string>) =>
    payload$.pipe(
      tap(() => this.onUpdateLoadingApplications(true)),
      switchMap(payload => this.jobPostApplicationService.getApplications(payload)),
      tap(applications => this.onGroupApplicationsByStatus(applications)),
      map(applications => this.onUpdateApplications(applications))
    )
  )

  readonly onFilterHitsFromAlgoliaResults = this.effect((payload$: Observable<Timestamp<IJobPostApplication>[]>) =>
    payload$.pipe(
      filter(isNotNil),
      map(hits => {
        const selectedGridStatus = this.get().selectedGridStatus
        const applications = this.get().applications

        const filteredHits = applications
          .filter(application => selectedGridStatus === 'ALL' || application.applicationState === selectedGridStatus)
          .filter(application => hits.find(hit => hit.id === application.id))

        // sort the filtered hits by the order of the algolia hits
        return filteredHits.sort((a, b) => hits.findIndex(hit => hit.id === a.id) - hits.findIndex(hit => hit.id === b.id))
      }),
      tap(hits => this.onUpdateGridHits(hits))
    )
  )

  readonly onFilterSwimlaneHitsFromAlgoliaResults = this.effect((payload$: Observable<Timestamp<IJobPostApplication>[]>) =>
    payload$.pipe(
      filter(isNotNil),
      map(hits => {
        const applications = this.get().applications
        const filteredHits = applications.filter(application => hits.find(hit => hit.id === application.id))
        // sort the filtered hits by the order of the algolia hits
        return filteredHits.sort((a, b) => hits.findIndex(hit => hit.id === a.id) - hits.findIndex(hit => hit.id === b.id))
      }),
      tap(hits => this.onGroupApplicationsByStatus(hits))
    )
  )

  readonly onGroupApplicationsByStatus = this.effect((payload$: Observable<Timestamp<IJobPostApplication>[]>) =>
    payload$.pipe(
      map(applications => {
        const groupedApplications = groupBy(applications, 'applicationState')
        return Object.keys(groupedApplications).map(
          status =>
            ({
              status,
              hits: groupedApplications[status],
              total: groupedApplications[status].length,
              isFirstPage: true,
            } as UpdateHitsByStatusPayload)
        )
      }),
      tap(groupedApplications => this.onSetSwimlaneHits(groupedApplications))
    )
  )

  // Reducers
  readonly onUpdateHitsByStatus = this.updater((state, payload: UpdateHitsByStatusPayload) => {
    const exitingHits = state.swimlaneHits[payload.status]?.hits ?? []
    const newHits = payload.hits ?? []

    const updatedState = { ...state.swimlaneHits }
    updatedState[payload.status] = {
      hits: payload.isFirstPage ? newHits : uniqBy([...exitingHits, ...newHits], 'id'),
      total: payload.total,
    }
    return { ...state, swimlaneHits: updatedState }
  })

  readonly onSetSwimlaneHits = this.updater((state, payload: UpdateHitsByStatusPayload[]) => {
    const updatedState = {} as any // using any to avoid having to define all the APPLICATION_STATE keys
    payload.forEach(hit => {
      updatedState[hit.status] = { hits: hit.hits, total: hit.total }
    })
    return { ...state, swimlaneHits: updatedState }
  })

  readonly onUpdateSwimlaneApplications = this.updater((state, payload: Timestamp<IJobPostApplication>[]) => ({
    ...state,
    swimlaneApplications: payload,
  }))

  readonly onTransferSwimlaneHit = this.updater((state, payload: TransferHitsByStatusPayload) => {
    const currentStatus = Object.keys(state.swimlaneHits).find(key =>
      state.swimlaneHits[key as APPLICATION_STATE].hits.find(hit => hit.id === payload.targetId)
    ) as APPLICATION_STATE | undefined

    if (!currentStatus) {
      return { ...state }
    }

    const updatedState = deepCopy(state.swimlaneHits)
    const currentHits = updatedState[currentStatus]?.hits ?? []
    const targetHits = updatedState[payload.targetStatus]?.hits ?? []
    const currentIndex = currentHits.findIndex(hit => hit.id === payload.targetId)

    if (currentIndex === -1) {
      return { ...state }
    }

    transferArrayItem(currentHits, targetHits, currentIndex, 0)

    updatedState[currentStatus] = {
      hits: currentHits,
      total: updatedState[currentStatus].total - 1,
    }

    updatedState[payload.targetStatus] = {
      hits: targetHits,
      total: (updatedState[payload.targetStatus]?.total ?? 0) + 1,
    }

    return { ...state, swimlaneHits: updatedState }
  })

  readonly onUpdateGridHits = this.updater((state, hits: Timestamp<IJobPostApplication>[]) => ({
    ...state,
    gridHits: hits,
  }))

  readonly onUpdateLoadingApplications = this.updater((state, loading: boolean) => ({
    ...state,
    loadingApplications: loading,
  }))

  readonly onUpdateApplications = this.updater((state, applications: Timestamp<IJobPostApplication>[]) => ({
    ...state,
    applications,
    loadingApplications: false,
  }))

  readonly onSelectedGridStatus = this.updater((state, status: APPLICATION_STATE | 'ALL') => ({
    ...state,
    selectedGridStatus: status,
    gridHits: status === 'ALL' ? state.applications : state.applications.filter(application => application.applicationState === status),
  }))

  readonly onCandidateApplicationStatusUpdated = this.updater((state, statusUpdate: CandidateApplicationStatusUpdateResponse) => {
    const status = statusUpdate.applicationState
    const previousStatus = statusUpdate.previousApplicationState

    // Update swimlaneHits
    const hitStatus = Object.keys(state.swimlaneHits).find(key =>
      state.swimlaneHits[key as APPLICATION_STATE].hits.find(hit => hit.id === statusUpdate.id)
    )

    const clonedSwimlaneHits = deepCopy(state.swimlaneHits)
    if (hitStatus) {
      const hitIndex = state.swimlaneHits[hitStatus as APPLICATION_STATE].hits.findIndex(hit => hit.id === statusUpdate.id)
      clonedSwimlaneHits[hitStatus as APPLICATION_STATE].hits[hitIndex].applicationState = status
      clonedSwimlaneHits[hitStatus as APPLICATION_STATE].hits[hitIndex].previousApplicationState = previousStatus
    }

    const gridHits = state.gridHits.filter(hit => hit.id !== statusUpdate.id)

    return { ...state, swimlaneHits: clonedSwimlaneHits, gridHits: gridHits }
  })

  readonly onResetState = this.updater(() => defaultState)
}
