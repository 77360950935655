import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { INotificationHandler } from '@engineering11/notifications-web/lib/models/notification-handler.model'
import { RequestedUpdateFulfilledNotification } from 'projects/shared-lib/src/lib/model/app-notification.model'
import { CandidateDetailTabs, EmployerNavigationService } from '../navigation.service'

@Injectable({ providedIn: 'root' })
export class RequestedUpdateFulfilledNotificationHandler implements INotificationHandler<RequestedUpdateFulfilledNotification, INotificationView> {
  notificationType = 'employer_application_updated'
  constructor(
    private router: Router,
    private userAppNotificationService: UserAppNotificationService,
    private navigationService: EmployerNavigationService
  ) {}

  onClick(notification: RequestedUpdateFulfilledNotification): boolean | Promise<boolean> {
    this.markAsRead(notification)
    return this.navigationService.toCandidateDetailPage(notification.jobPostId, notification.candidateApplicationId, CandidateDetailTabs.Application)
  }

  markAsRead(notification: RequestedUpdateFulfilledNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}
