import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router, RouterModule } from '@angular/router'
import * as fromReducers from '@employer/app/store/reducers'
import { selectors } from '@employer/app/store/selectors'
import { ICustomerDepartment, ICustomerLocation } from '@engineering11/customer-web'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import { IMultiSelectOption, mapToMultiselectOptions } from '@engineering11/ui-lib/e11-multi-select'
import { LetDirective } from '@ngrx/component'
import { Store } from '@ngrx/store'
import { Observable, filter, map, of, startWith } from 'rxjs'
import { CareersPageFilteringComponent } from './partials/careers-page-filtering.component'
import { ReportFilterFormComponent } from './partials/report-filtering-form.component'
import { SectionNavigationComponent } from './partials/section-navigation.component'
import { ISummaryCard, summaryList } from './report-filtering.model'
import { SingleJobFilteringComponent } from './single-job-filtering.component'
export interface IReportFilteringForm {
  startDate: string
  endDate: string
  dateType: string
  jobStatus: string[]
  locationAddress: string[]
  city: string[]
  department: string[]
  employmentType: string[]
  experienceLevel: string[]
  employeeLocation: string[]
}
export interface IReportTab {
  id: string
  label: string
}
@Component({
  selector: 'report-filtering-nav',
  standalone: true,
  imports: [
    CommonModule,
    E11DividerModule,
    SectionNavigationComponent,
    ReportFilterFormComponent,
    RouterModule,
    SingleJobFilteringComponent,
    CareersPageFilteringComponent,
    LetDirective,
  ],
  template: `
    <div class="e11-pr-2 e11-py-4">
      <section-navigation (scrollTo)="scrollToSection($event)"></section-navigation>
      <e11-divider [margin]="1" />
      <ng-container *ngrxLet="careersPageRoute$ as careersPageRoute">
        @if (careersPageRoute) {
        <careers-page-filtering></careers-page-filtering>
        } @else {
        <report-filter-form
          [isSingleJobPostRoute]="(isSingleJobPostRoute$ | async) ?? false"
          [cityOptions]="(listOfCities$ | async) ?? []"
          [location]="(listOfLocations$ | async) ?? []"
          [department]="(listOfDepartments$ | async) ?? []"
          [loading]="(loading$ | async) ?? false"
        ></report-filter-form>
        <single-job-filtering
          [isSingleJobPostRoute]="(isSingleJobPostRoute$ | async) ?? false"
          (scrollTo)="scrollToSection($event)"
        ></single-job-filtering>
        }
      </ng-container>
    </div>
  `,
})
export class ReportFilteringNavComponent implements OnInit {
  listOfDepartments$?: Observable<IMultiSelectOption[]>
  listOfLocations$?: Observable<IMultiSelectOption[]>
  listOfCities$?: Observable<IMultiSelectOption[]>

  summaryList: ISummaryCard[] = summaryList
  loading$: Observable<boolean> = this.store.select(selectors.loading)
  isSingleJobPostRoute$: Observable<boolean> = of(false)
  careersPageRoute$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => this.router.url.includes('reports/careers-page'))
  )

  constructor(private store: Store<fromReducers.customer.State>, private router: Router) {}

  ngOnInit(): void {
    this.isSingleJobPostRoute$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(null),
      map(() => this.router.url.includes('reports/job-posts/'))
    )

    this.loading$ = this.store.select(selectors.loading)

    this.getLocation()
    this.getCities()
    this.getDepartment()
  }
  getLocation() {
    this.listOfLocations$ = this.store.select(selectors.getCustomerLocationEntities).pipe(
      map((locations: ICustomerLocation[]) => {
        return mapToMultiselectOptions(
          locations,
          l => l.name,
          l => l.name
        )
      })
    )
  }
  getDepartment() {
    this.listOfDepartments$ = this.store.select(selectors.getCustomerDepartmentEntities).pipe(
      map((departments: ICustomerDepartment[]) =>
        mapToMultiselectOptions(
          departments,
          d => d.name,
          d => d.name
        )
      )
    )
  }
  getCities() {
    this.listOfCities$ = this.store.select(selectors.getCustomerLocationEntities).pipe(
      map((locations: ICustomerLocation[]) => {
        return mapToMultiselectOptions(
          locations.filter(l => l.city),
          l => l.city!,
          l => l.city!
        )
      })
    )
  }
  scrollToSection(id: string) {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
