import { ChangeDetectionStrategy, Component } from '@angular/core'
import { ConfigStore } from '@engineering11/config-web'

@Component({
  selector: 'messaging-nav-groups',
  template: `
    <div class="nav-messages-container e11-flex e11-flex-col e11-gap-2">
      <div class="e11-w-full e11-rounded e11-bg-skin-grey/20 e11-py-2">
        <ng-container *ngTemplateOutlet="groupHeader; context: { title: 'Application', subtitle: 'Direct Messages' }"></ng-container>
        <nav-item-direct-messages></nav-item-direct-messages>
      </div>
      <div class="e11-w-full e11-rounded e11-bg-skin-grey/20 e11-py-2" *ngIf="(features$ | async)?.jobApplicationChannels">
        <ng-container *ngTemplateOutlet="groupHeader; context: { title: 'Application', subtitle: 'Group Discussion' }"></ng-container>
        <nav-item-job-application-messages></nav-item-job-application-messages>
      </div>
      <div class="e11-w-full e11-rounded e11-bg-skin-grey/20 e11-py-2">
        <ng-container *ngTemplateOutlet="groupHeader; context: { title: 'Candidate Pool', subtitle: 'Direct Messages' }"></ng-container>
        <nav-item-candidate-pool-messages></nav-item-candidate-pool-messages>
      </div>

      <ng-template #groupHeader let-title="title" let-subtitle="subtitle">
        <div class="e11-flex e11-font-medium e11-justify-between e11-items-center e11-mb-2 e11-px-2 e11-text-skin-white">
          <p class="e11-text-md e11-mb-0">{{ title | translate }}</p>
          <p class="e11-text-xs e11-mb-0">({{ subtitle | translate }})</p>
        </div>
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagingNavGroupsComponent {
  features$ = this.configStore.features$
  constructor(private configStore: ConfigStore) {}
}
