import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IEmployerUser } from '@cnect/user-shared'
import { Timestamp } from '@engineering11/types'
import { QuestionType } from 'shared-lib'
import { IInterviewQuestion } from '../../interview-question.model'

@Component({
  selector: 'ui-interview-question',
  template: `<div class="e11-flex e11-border-b e11-border-skin-app-borders">
    <div *ngIf="question" class="e11-grow e11-w-2/3">
      <div class="e11-block md:e11-flex e11-py-4">
        <div class="e11-grow">
          <p class="e11-mb-0 e11-line-clamp-2 e11-pr-0 md:e11-pr-4">{{ question.question | translate }}</p>
        </div>
        <div class="e11-flex e11-justify-between md:e11-justify-end">
          <div class="e11-flex e11-items-center e11-pr-0 md:e11-pr-2">
            <e11-chip
              class="e11-mr-4 e11-mb-0"
              [value]="question.authorName"
              [containerClassOverrides]="'!e11-mb-0'"
              [contentClassOverrides]="'e11-whitespace-nowrap'"
            ></e11-chip>
          </div>
          <div class="e11-flex e11-items-center e11-pr-0 md:e11-pr-4">
            <ng-container *ngIf="question.inputType === QuestionType.EssayOrVideo; else singleIcon">
              <span class="material-icons-outlined md-14 e11-mb-0 e11-mr-1"> article </span>
              <span class="material-icons-outlined md-14 e11-mb-0 e11-mr-1"> videocam </span>
            </ng-container>
            <ng-template #singleIcon>
              <span class="material-icons-outlined md-14 e11-mb-0 e11-mr-1">
                {{ question.inputType === QuestionType.Video ? 'videocam' : 'article' }}
              </span>
            </ng-template>
            <span class="e11-text-xs e11-mr-1 e11-hidden md:e11-flex">
              {{ question.inputType | questionInputType | translate }}
            </span>

            <span class="e11-whitespace-nowrap e11-text-xs e11-mr-2">{{ question.__updatedAt | date : 'MMM dd yyyy ' }}</span>
          </div>
          <div class="e11-flex e11-items-center e11-pr-0">
            <span
              class="material-icons-outlined e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer md-18 md:md-24"
              (click)="editClicked.emit(question)"
            >
              edit
            </span>
          </div>
        </div>
      </div>
    </div>
  </div> `,
})
export class InterviewQuestionComponent implements OnInit {
  user?: IEmployerUser

  QuestionType = QuestionType

  @Input() question?: Timestamp<IInterviewQuestion> | null

  @Output() editClicked = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}
}
