import { Injectable } from '@angular/core'
import { BaseRegistrationConfigProvider, IRegistrationConfig } from '@engineering11/registration-web'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class RegistrationConfigProvider extends BaseRegistrationConfigProvider {
  constructor(private store: Store, private logger: E11Logger, private appConfig: AppConfigService) {
    super()
  }
  get config(): IRegistrationConfig {
    return {
      registrationBaseURL: environment.services.registration,
      authBaseURL: environment.services.auth,
      authTenantId: this.appConfig.config.auth.tenantId,
      token$: this.store.select(selectors.getCurrentToken),
      cdnName: environment.cdnCname,
      basePlatformHost: environment.basePlatformHost,
    }
  }
}
