import { Component } from '@angular/core'
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'
import * as fromReduce from '@employer/app/store/reducers'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import jwt_decode from 'jwt-decode'
import { userActions } from 'shared-lib'

const DEFAULT_REDIRECT_PATH = 'home'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthenticationLoginComponent {
  constructor(public router: Router, private store: Store<fromReduce.user.State>, private logger: E11Logger) {}

  onError(error: Error): void {
    throw error
  }

  onForgottenPassword() {
    this.router.navigate(['/auth/recover'])
  }

  async onSubmit(token: string): Promise<void> {
    const redirectPath = this.buildRedirectPath()
    const decoded: any = jwt_decode(token)
    this.store.dispatch(userActions.OnLogIn({ token, appUserId: decoded.appUserId, redirectPath }))
  }

  private buildRedirectPath() {
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    if (!primary.segments.length) return DEFAULT_REDIRECT_PATH
    const redirectPath = primary.segments[primary.segments.length - 1].path
    if (redirectPath === 'login') return DEFAULT_REDIRECT_PATH
    else return redirectPath
  }
}
