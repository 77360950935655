import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IEmployerUser } from '@cnect/user-shared'
import { EmployerUserService } from '@employer/app/services/employer-user.service'
import { CustomerService, ICustomerDepartment, ICustomerLocation } from '@engineering11/customer-web'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from, of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { selectors } from '../selectors'
import {
  CustomerActionTypes,
  ErrorAction,
  GetCustomerDepartmentSuccess,
  GetCustomerLocationSuccess,
  GetCustomerUsersSuccess,
} from './customer.actions'

@Injectable()
export class CustomerEffects {
  constructor(
    public router: Router,
    private actions$: Actions,
    private customerService: CustomerService,
    private employerUserService: EmployerUserService,
    private store: Store
  ) {}

  onGetCustomerDepartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActionTypes.getDepartment),
      switchMap(action => this.store.select(selectors.getCustomerKey)),
      filter(customerKey => !!customerKey),
      switchMap(customerKey => from(this.customerService.departments.getAllActive(customerKey as string))),
      map((response: ICustomerDepartment[]) => new GetCustomerDepartmentSuccess(response)),
      catchError(error => of(new ErrorAction(error)))
    )
  )

  onGetCustomerLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActionTypes.getLocation),
      switchMap(action => this.store.select(selectors.getCustomerKey)),
      filter(customerKey => !!customerKey),
      switchMap(customerKey => from(this.customerService.locations.getAllActive(customerKey as string))),
      map((response: ICustomerLocation[]) => new GetCustomerLocationSuccess(response)),
      catchError(error => of(new ErrorAction(error)))
    )
  )

  onGetActiveCustomerUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActionTypes.getActiveCustomerUsers),
      switchMap(action => this.store.select(selectors.getCustomerKey)),
      filter(customerKey => !!customerKey),
      switchMap(customerKey => this.employerUserService.getAllActiveByCustomer(customerKey as string)),
      map((response: IEmployerUser[]) => new GetCustomerUsersSuccess(response)),
      catchError(error => of(new ErrorAction(error)))
    )
  )
}
