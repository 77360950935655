import { IContentAssociation, IContentDocument, IContentItem } from '@engineering11/content-web'
import { ICustomerLocation } from '@engineering11/customer-web'
import { NamedMapping } from '@engineering11/types'
import {
  DegreeLevel,
  EmployeeLocation,
  EmploymentType,
  IRequirableCertification,
  IRequirableSkill,
  JobProfileStatus,
  PayRateTypeEnum,
} from 'shared-lib'
import { IJobCloseSchedule } from './job-post.model'

export const CONTENT_TYPE_JOB_PROFILE = 'job-profile'

export interface IJobProfile extends IContentDocument {
  /** EXTERNAL job title */
  jobTitle?: string
  /** INTERNAL job title */
  title?: string
  department?: string
  employmentType?: EmploymentType
  internalCode?: string
  experienceLevel?: string
  locationAddress?: ICustomerLocation
  employeeLocation?: EmployeeLocation
  description?: string // Use as job description (content sdk standardized naming)
  minimumQualifications?: string
  responsibilities?: string
  educationLevel?: DegreeLevel
  // setHours?: boolean
  requiredTravel?: boolean
  travelPercentage?: number
  compensationHigh?: number | null
  compensationLow?: number | null
  exposeCompensation?: boolean
  payRateType?: PayRateTypeEnum
  benefits?: string
  selectedSkills?: IRequirableSkill[]
  selectedCertifications?: IRequirableCertification[]
  schedule?: string
  published?: boolean
  publishedDate?: Date
  version?: string
  coreResponses?: NamedMapping<number | undefined>
  status: JobProfileStatus
  jobPaused?: boolean
  jobCloseSchedule?: IJobCloseSchedule
  /** keywords used to initially generate the job, if any */
  keywords?: string
  __updatedAt?: Date
  __createdAt?: Date
}

export interface IJobProfileContentItem extends IContentItem<IJobProfile> {
  document: IJobProfile
  associations: Map<string, IContentAssociation>
}

export interface IPublishedJobProfile extends IJobProfile {
  // status: JobProfileStatus.Published
}

export function isJobProfile(document: IContentDocument): document is IJobProfile {
  return document.contentType === CONTENT_TYPE_JOB_PROFILE
}
