import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { EmployerNavigationService } from '@employer/app/services/navigation.service'
import { Observable, Subject, filter, map, of, takeUntil } from 'rxjs'
import { IReportTab } from '../report-filtering-nav.component'
export const tabs: IReportTab[] = [
  {
    id: 'job-posts',
    label: 'Job Posts',
  },
  {
    id: 'applications',
    label: 'Applications',
  },
  {
    id: 'engagements',
    label: 'Engagement',
  },
  {
    id: 'compliance',
    label: 'Compliance',
  },
]
@Component({
  selector: 'section-navigation',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div>
      @for (tab of tabsList; track $index) {
      <p
        class=" e11-text-sm e11-font-semibold e11-mb-1 e11-p-2 e11-cursor-pointer hover:e11-bg-skin-primary-accent/20"
        [ngClass]="{
          'e11-text-skin-primary hover:e11-bg-grey/20': activeTab?.id === tab.id,
          'e11-text-skin-primary-accent': activeTab?.id !== tab.id
        }"
        (click)="onClick(tab)"
      >
        {{ tab.label }}
      </p>
      }
      <p
        class=" e11-text-sm e11-font-semibold e11-mb-1 e11-p-2 e11-cursor-pointer e11-text-skin-primary-accent hover:e11-bg-skin-primary-accent/20"
        [ngClass]="{
          'e11-text-skin-primary hover:e11-bg-grey/20': isCareerPage$ | async,
        }"
        (click)="linkToCareersPage()"
      >
        Careers Page
      </p>
      <div class="e11-ml-4" *ngIf="isCareerPage$ | async">
        @for (item of careersPageSectionList; track $index) {
        <p
          class=" e11-text-sm e11-font-semibold e11-mb-1 e11-p-2 e11-cursor-pointer e11-text-skin-primary-accent hover:e11-bg-skin-primary-accent/20"
          (click)="scrollTo.emit(item.id)"
        >
          {{ item.label }}
        </p>
        }
      </div>
    </div>
  `,
})
export class SectionNavigationComponent implements OnInit, OnDestroy {
  @Output() scrollTo = new EventEmitter<string>()
  tabsList: IReportTab[] = tabs
  activeTab?: IReportTab = this.tabsList[0]
  destroy$: Subject<boolean> = new Subject<boolean>()
  isCareerPage$: Observable<boolean> = of(false)
  careersPageSectionList = [
    {
      label: 'Site Visitors',
      id: 'site-visitors',
    },
    {
      label: 'Job Post Views',
      id: 'job-post-views',
    },
    {
      label: 'Conversions',
      id: 'conversions',
    },
  ]
  constructor(private employerNavigationService: EmployerNavigationService, private route: ActivatedRoute, private router: Router) {}
  onClick(tab: IReportTab) {
    this.employerNavigationService.toReports(tab.label)
  }
  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.activeTab = this.tabsList.find(t => t.label === params.tab) ?? this.tabsList[0]
    })
    this.isCareerPage$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.url.includes('/reports/careers-page'))
    )
  }
  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
  linkToCareersPage() {
    this.router.navigate(['/reports/careers-page'])
  }
}
