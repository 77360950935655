import { Injectable } from '@angular/core'
import { FilesConfigProvider, IFilesConfig } from '@engineering11/files-web'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { selectors } from '../store/selectors'

@Injectable({ providedIn: 'root' })
export class CnectFilesConfigProvider extends FilesConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): IFilesConfig {
    return {
      filesBaseURL: environment.services.files,
      CNAME: environment.cdnCname,
      customerKey$: this.store.select(selectors.getCustomerKey$), // the current user's customerKey
      token$: this.store.select(selectors.getCurrentToken),
    }
  }
}
