import { Injectable } from '@angular/core'
import { selectors } from '@employer/app/store/selectors'
import { environment } from '@employer/environments/environment'
import { FilesService, IPdfGenerator } from '@engineering11/files-web'
import { isNotNil } from '@engineering11/utility'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { filter, map, of } from 'rxjs'
import { CreateJobApplicationTokenDTO, IJobApplicationShareData, IJobApplicationToken } from './job-post-application-share.model'

@Injectable({ providedIn: 'root' })
export class JobPostApplicationShareService {
  private restApiClient = new RestApiClient({
    baseUrl: environment.services.jobs,
    token: this.store.select(selectors.getCurrentToken).pipe(filter(isNotNil)),
  })
  private unAuthApiClient = new RestApiClient({ baseUrl: environment.services.jobs, token: of(null) })

  constructor(private store: Store, private filesService: FilesService) {}

  getAllByJobPost(jobPostId: string) {
    return this.restApiClient.get<IJobApplicationToken[]>(`job-posts/${jobPostId}/job-post-application-shares`).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  create(newTokenData: CreateJobApplicationTokenDTO) {
    const { shareName, jobApplicationId, jobPostId, sections, maskPersonalDetails } = newTokenData
    const req = { shareName, jobApplicationId, sections, maskPersonalDetails }
    return this.restApiClient.post<IJobApplicationToken>(`job-posts/${jobPostId}/job-post-application-shares`, req).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  delete(token: string) {
    return this.restApiClient.delete(`job-posts/job-post-application-shares/${token}`)
  }

  /** Calls a public endpoint */
  getApplicationFromShareToken(token: string) {
    //call this on init of share page to grab the application. Returns observable.
    return this.unAuthApiClient.get<IJobApplicationShareData>(`job-posts/job-post-application-shares/${token}`).pipe(map(res => res.data))
  }

  getAllByJobApplication(jobPostId: string, jobApplicationId: string) {
    return this.restApiClient
      .get<IJobApplicationToken[]>(`job-posts/${jobPostId}/job-post-application-shares/${jobApplicationId}`)
      .pipe(map(res => res.data))
  }

  async downloadPDF(shareTokenString: string, applicationId: string) {
    const url = `${buildOriginalUrlEmployer(shareTokenString)}?pdf=true`
    const pdf: IPdfGenerator = {
      url: encodeURIComponent(url),
      delay: 5000,
      isSinglePage: true,
    }
    await this.filesService.downloadPDF(pdf, `${applicationId}-${new Date().getTime()}.pdf`)
  }
}

export function buildOriginalUrlEmployer(token: string) {
  return `${environment.employerBaseUrl}#/public/application-share/application/${token}`
}
